<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Setores</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="setorList"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog persistent v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                color="#1daf80"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Novo Setor
              </v-btn>
            </template>
            <modal-cadastro
              @close="closeDialogCadastrar"
              @response="atualizarSetorPosCadastro"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal"
                >Tem certeza de que deseja excluir este Setor?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1daf80" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :loading="loadingDelete"
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            persistent
            max-width="500px"
            v-if="dialogEdicao"
            v-model="dialogEdicao"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :setor="editedSetor"
              @close="closeDialogEdited"
              @response="atualizarSetorPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="allowDeletarBtn" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalCadastro from "@/components/setor/modal_cadastro";
import modalEdicao from "@/components/setor/modal_edicao";
import SetorService from "../services/setor_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import endpoints from "@/router/endpoints";
import AuthService from "@/services/auth_service";

export default {
  mixins: [Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    dialog: false,
    dialogObs: false,
    editedSetor: {},
    idToDeleted: 0,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    dialogCadastro: false,
    setorList: [],
    headers: [
      {
        text: "Descrição",
        align: "start",
        sortable: true,
        value: "descricao",
      },
      {
        text: "Desativado Em",
        align: "start",
        sortable: true,
        value: "desativadoEm",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    loadingDelete: false,
    services: {
      setor: SetorService.build(),
      authService: AuthService.build(),
    },
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarSetorList();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarSetorList();
      }
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarSetorList();
      },
      deep: true,
    },
  },

  mounted() {
    this.atualizarSetorList();
  },
  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints["SetorController::POST->registerSetor"]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["SetorController::POST->updateSetor/$1"]
      );
    },
    allowDeletarBtn() {
      return this.services.authService.temPermissao(
        endpoints["SetorController::POST->deleteSetor/$1"]
      );
    },
  },
  methods: {
    atualizarSetorPosCadastro() {
      this.atualizarSetorList();
      this.closeDialogCadastrar();
    },
    atualizarSetorPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarSetorList();
    },
    async atualizarSetorList() {
      this.loading = true;
      await this.services.setor
        .getSetorPaginatedList({
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          sort: this.options.order,
          desc: this.options.desc,
          search: this.search,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, setor } = data;
          this.totalList = pagination.num_rows;
          this.setorList = setor.map(
            ({ id, descricao, id_centro_custo, desativado_em }) => {
              return {
                id,
                descricao,
                centro_custo_id: id_centro_custo,
                desativadoEm:
                  desativado_em == "0000-00-00 00:00:00"
                    ? "Ativado"
                    : "Desativado em " + desativado_em,
              };
            }
          );
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(item) {
      this.editedSetor = item;
      this.dialogEdicao = true;
    },

    deleteItem({ id }) {
      this.idToDeleted = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      this.services.setor
        .deletar(this.idToDeleted)
        .then(async ({ status }) => {
          this.$_ACTIONS_showSnackbarMessage({
            message:
              status === 200
                ? "O Setor foi deletado com sucesso."
                : this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
          status === 200 && (await this.atualizarSetorList());
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingDelete = false;
          this.closeDelete();
        });
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
