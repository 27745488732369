<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline"
          >Consulta -
          {{
            formatarData(infos.agendamento_data, infos.agendamento_hora)
          }}</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <v-container>
      <div class="container my-2">
        <div class="row justify-content-center">
          <div class="col-10">
            <h4><strong>Tutor: </strong>{{ infos.nome_pessoa }}</h4>
            <h4><strong>Pet: </strong>{{ infos.nome_pet }}</h4>
            <h4><strong>Raça: </strong>{{ infos.nome_raca }}</h4>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-10">
            <div class="text-center">
              <v-btn
                class="btn-mob-atendimento"
                :dark="url == '' ? false : true"
                :color="'#2C7091'"
                small
                width="120px"
                :disabled="url == ''"
                @click="entrarSala(url)"
              >
                {{ url == "" ? "Aguarde" : "Entrar sala" }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import AgendamentoSalaService from "@/services/agendamento_sala_service.js";

export default {
  mixins: [Mixin],
  components: {},
  props: {
    infos: {
      type: Object,
      // required: true,
      required: false,
    },
  },
  data() {
    return {
      url: "",
      service: {
        agendamentoSalaService: AgendamentoSalaService.build(),
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getSalaConferencia(this.infos.id_requisicao_procedimento);
  },
  methods: {
    async getSalaConferencia(idRequisicaoProcedimento) {
      await this.service.agendamentoSalaService
        .getSalaAgendamento(idRequisicaoProcedimento)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.url = data.urlmeeting;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    entrarSala(url) {
      window.open(url, "_blank");
    },
    formatarData(data, hora) {
      let arrData = data.split("-");
      let arrHora = hora.split(":");
      return `${arrData[2]}/${arrData[1]}/${arrData[0]} ${arrHora[0]}:${arrHora[1]}`;
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
