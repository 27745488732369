import HTTPService from "@/services/http_service.js";

export default class HistoricoComprasService extends HTTPService {
  resource = "loja/compras";

  static build() {
    return new HistoricoComprasService();
  }
  /**
        @returns {Promise<Response>}
     */

  // Lista Compras Paginado
  getHistoricoCompraPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(`${this.resource}`, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  // Lista Compras por Loja
  getHistoricoCompra(id_loja, id_status = 0) {
    // Status: 0 todos, 1 confirmados, 2 Aguardando, 3 Falhou
    return this.get(`${this.resource}/list/${id_loja}/${id_status}`);
  }

  // Lista Compras por Usuario ID
  getHistoricoComprabyIdUsuario(id_usuario, id_status = 0) {
    // Status: 0 todos, 1 confirmados, 2 Aguardando, 3 Falhou
    return this.get(`${this.resource}/usuario/${id_usuario}/${id_status}`);
  }

  // Detalhe Compra por ID Compra
  getDetalheCompra(id_compra) {
    return this.get(`${this.resource}/detalhe/${id_compra}`);
  }
}
