<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Pré-Agendamento</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="preAgendamentoList"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog persistent v-model="dialogCadastrar" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                color="#1daf80"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Novo Pré-Agendamento
              </v-btn>
            </template>
            <modal-cadastro
              @close="closeDialogCadastrar"
              @response="atualizarPosCadastro"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal"
                >Tem certeza de que deseja excluir este Pré-Agendamento?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1daf80" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :loading="loadingDelete"
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            persistent
            max-width="500px"
            v-if="dialogEdicao"
            v-model="dialogEdicao"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :preAgendamento="editedItem"
              @close="closeDialogEdited"
              @response="atualizarPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="allowDeletarBtn" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalCadastro from "@/components/preagendamento/modal_cadastro";
import modalEdicao from "@/components/preagendamento/modal_edicao";
import preAgendamentoService from "../services/preagendamento_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import endpoints from "../router/endpoints";
import AuthService from "@/services/auth_service";

export default {
  mixins: [Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    dialog: false,
    dialogObs: false,
    editedItem: {},
    preAgendamentoIdToDelete: 0,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    dialogCadastro: false,
    preAgendamentoList: [],
    headers: [
      {
        text: "Cliente",
        align: "start",
        sortable: true,
        value: "clienteNome",
      },
      {
        text: "Telefone",
        align: "start",
        sortable: true,
        value: "clienteTelefone",
      },
      {
        text: "Especialidade",
        align: "start",
        sortable: true,
        value: "descricaoEspecialidade",
      },
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "statusDescricao",
      },
      {
        text: "Data",
        align: "start",
        sortable: true,
        value: "agendaData",
      },
      {
        text: "Horário",
        align: "start",
        sortable: true,
        value: "agendaHorario",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    loadingDelete: false,
    services: {
      preAgendamentoService: preAgendamentoService.build(),
      authService: AuthService.build(),
    },
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarPreAgendamentoList();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarPreAgendamentoList();
      }
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarPreAgendamentoList();
      },
      deep: true,
    },
  },

  mounted() {
    this.atualizarPreAgendamentoList();
  },
  computed: {
    allowCadastrarBtn() {
      return true; // this.services.authService.temPermissao(endpoints["CentroCustoController::POST->registerCentroCusto"]);
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["CentroCustoController::POST->updateCentroCusto/$1"]
      );
    },
    allowDeletarBtn() {
      return this.services.authService.temPermissao(
        endpoints["CentroCustoController::POST->deleteCentroCusto/$1"]
      );
    },
  },
  methods: {
    atualizarPosCadastro() {
      this.atualizarPreAgendamentoList();
      this.closeDialogCadastrar();
    },
    atualizarPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarPreAgendamentoList();
    },
    async atualizarPreAgendamentoList() {
      this.loading = true;
      await this.services.preAgendamentoService
        .getPaginatedList({
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          sort: this.options.order,
          desc: this.options.desc,
          search: this.search,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, preagendamentos } = data;
          this.totalList = pagination.num_rows;
          this.preAgendamentoList = preagendamentos.map(
            ({
              pre_agendamento_id,
              id_agenda_especialidade,
              cliente_nome,
              cliente_telefone,
              id_especialidade,
              descricao_especialidade,
              status_id,
              status_descricao,
              agenda_hora_inicio,
              agenda_hora_final,
              agenda_data,
            }) => {
              return {
                preAgendamentoId: pre_agendamento_id,
                idAgendaEspecialidade: id_agenda_especialidade,
                clienteNome: cliente_nome,
                clienteTelefone: cliente_telefone,
                idEspecialidade: id_especialidade,
                descricaoEspecialidade: descricao_especialidade,
                statusId: status_id,
                statusDescricao: status_descricao,
                agendaHoraInicio: agenda_hora_inicio,
                agendaHoraFinal: agenda_hora_final,
                agendaData: agenda_data
                  .split("-")
                  .reverse()
                  .join("/"),
                agendaHorario: `${agenda_hora_inicio} - ${agenda_hora_final}`,
              };
            }
          );
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(item) {
      this.editedItem = item;
      this.dialogEdicao = true;
    },

    deleteItem({ id }) {
      this.preAgendamentoIdToDelete = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      this.services.preAgendamentoService
        .deletar(this.preAgendamentoIdToDelete)
        .then(async ({ status }) => {
          this.$_ACTIONS_showSnackbarMessage({
            message:
              status === 200
                ? "O Pré-Agendamento foi deletado com sucesso."
                : this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
          status === 200 && (await this.atualizarPreAgendamentoList());
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingDelete = false;
          this.closeDelete();
        });
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
