<template>
  <body
    class="
      authentication-bg authentication-bg-pattern
      d-flex
      align-items-center
    "
  >
    <div class="home-btn d-none d-sm-block">
      <a href="https://vettree.prolins.com.br/"
        ><i class="fas fa-home h2 text-white"></i
      ></a>
    </div>

    <div class="account-pages w-100 mt-5 mb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card shadow">
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <a href="index.html">
                    <span>
                      <img
                        src="@/assets/images/Logotipo-Televet-Play-Color.png"
                        alt=""
                        height="200px"
                      />
                      <!-- <img
                        src="@/assets/images/logo-login.png"
                        alt=""
                        height="200px"
                      /> -->
                    </span>
                  </a>
                </div>

                <div class="form-group mb-1 text-center">
                  <button
                    class="
                      btn btn-app-primary btn-block btn-rounded
                      width-lg
                      font-weight-bold
                    "
                    @click="loginRecepcao"
                  >
                    <span>Recepção</span>
                  </button>
                </div>

                <div class="form-login">
                  <div class="form-group mb-1 text-center">
                    <button
                      class="
                        btn btn-app-primary btn-block btn-rounded
                        width-lg
                        font-weight-bold
                      "
                      @click="loginAdministrativo"
                    >
                      <span>Administrativo</span>
                    </button>
                  </div>
                  <div class="form-group mb-1 text-center">
                    <button
                      class="
                        btn btn-app-primary btn-block btn-rounded
                        width-lg
                        font-weight-bold
                      "
                      @click="loginMedico"
                    >
                      <span>Médico</span>
                    </button>
                  </div>
                  <div class="form-group mb-1 text-center">
                    <button
                      class="
                        btn btn-app-primary btn-block btn-rounded
                        width-lg
                        font-weight-bold
                      "
                      @click="loginAssistente"
                    >
                      <span>Assistente</span>
                    </button>
                  </div>
                  <div class="form-group mb-1 text-center">
                    <button
                      class="
                        btn btn-app-primary btn-block btn-rounded
                        width-lg
                        font-weight-bold
                      "
                      @click="loginCliente"
                    >
                      <span>Cliente</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </body>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  mounted() {
    if (this.$route.query.confirmado) {
      this.$_ACTIONS_showSnackbarMessage({
        message: "O e-mail foi confirmado com sucesso!",
        color: "sucess",
      });
    }
  },
  data: () => ({}),
  methods: {
    loginAdm() {
      let resp = { id: 1, description: "adm" };
      this.$emit("login", resp);
      this.$router.replace("login");
    },
    // loginEmpresa(){
    // resp =this.$emit('login', 'empresa')
    //     this.$router.replace('login_empresa')
    // },
    loginMedico() {
      let resp = { id: 2, description: "medico" };
      this.$emit("login", resp);
      this.$router.replace("login");
    },
    loginAssistente() {
      let resp = { id: 3, description: "assistente" };
      this.$emit("login", resp);
      this.$router.replace("login");
    },
    loginRecepcao() {
      let resp = { id: 56, description: "recepcionista" };
      this.$emit("login", resp);
      this.$router.replace("login");
    },
    loginCliente() {
      let resp = { id: 4, description: "paciente" };
      this.$emit("login", resp);
      this.$router.replace("login");
    },
    loginAdministrativo() {
      let resp = { id: 5, description: "administrativo" };
      this.$emit("login", resp);
      this.$router.replace("login");
    },
  },
};
</script>
