<template>
  <div class="container">
    <div class="container" v-if="!dialogNovaConsulta">
      <div class="row justify-content-center">
        <div class="col-lg-8 form-group clearfix mb-3">
          <label>Tipo Procedimento</label>
          <v-select
            style="padding-top: 0 !important"
            :items="tipoProcedimentoList"
            v-model="tipoProcedimentoId"
            item-text="tipoProcedimentoDescricao"
            item-value="tipoProcedimentoId"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8 form-group clearfix mb-3">
          <label for="nome">Procedimento</label>
          <v-autocomplete
            class="mb-0 mt-0"
            v-model="procedimentoId"
            :items="procedimentosList"
            :loading="loadingProcedimento"
            :disabled="tipoProcedimentoId != '0' ? false : true"
            color="#1daf80"
            hide-no-data
            hide-details
            :search-input.sync="search"
            label="Buscar por nome"
            item-text="descricao"
            item-value="value"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.descricao"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <!-- <v-select
            style="padding-top: 0 !important"
            :items="procedimentosList"
            v-model="procedimentoId"
            item-text="descricao"
            item-value="value"
          /> -->
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8 form-group clearfix mb-3">
          <label>Formulários</label>
          <v-select
            style="padding-top: 0 !important"
            :items="formulariosList"
            v-model="formularioId"
            item-text="descricao"
            item-value="id"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8 text-end form-group clearfix mb-3">
          <button
            class="btn btn-app-primary btn-padding font-weight-bold"
            @click="postNewFormulario"
            :disabled="disabledBtn"
          >
            Criar
          </button>
        </div>
      </div>
    </div>
    <modal-nova-anaminese
      v-else
      :id_formulario="parseInt(formularioId)"
      :id_agendamento="id_agendamento"
      :id_requisicao="id_requisicao"
      :tutor_nome="paciente.NomeTutor"
      :pet_nome="paciente.NomePet"
      @close="dialogNovaConsulta = false"
      @finalizarClose="concluir()"
    />
  </div>
</template>

<script>
import ProcedimentoService from "@/services/procedimento_service.js";
import TipoProcedimentoService from "@/services/tipo_procedimento_service.js";
import FormularioService from "@/services/formulario_service.js";
import ModalNovaAnaminese from "@/components/recepcao_medico/infoChamada/nova_anaminese/atendimento_nova_anaminese.vue";

export default {
  components: {
    ModalNovaAnaminese,
  },
  data: () => ({
    procedimentoId: 0,
    procedimentosList: [],
    tipoProcedimentoId: JSON.parse(sessionStorage.vuex).perfil.id != 3 ? 2 : 4,
    tipoProcedimentoList: [],
    procedimentosListByTipoProcedimento: [],
    formulariosList: [],
    formularioId: 0,
    tipoProcedimentoService: TipoProcedimentoService.build(),
    procedimentoService: ProcedimentoService.build(),
    formularioService: new FormularioService(),
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
    medicoId: JSON.parse(sessionStorage.vuex).usuario.dados.medicoId,
    dialogNovaConsulta: false,
    petId: 0,
    tutorId: 0,
    id_agendamento: 0,
    id_requisicao: 0,
    disabledBtn: true,
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
  }),
  props: {
    paciente: {
      required: true,
    },
  },
  watch: {
    tipoProcedimentoId() {
      this.procedimentoId = 0;
      this.formularioId = 0;
      this.getProcedimentos();
      this.validarForm();
    },
    procedimentoId() {
      this.formularioId = 0;
      this.getFormularioByProcedimentoId();
      this.validarForm();
    },
    formularioId() {
      this.validarForm();
    },
  },
  mounted() {
    this.petId = this.paciente.PetCodigo;
    this.tutorId = this.paciente.TutorCodigo;
    this.getTipoProcedimentos();
    this.getProcedimentos();
  },
  computed: {},
  methods: {
    async getProcedimentos() {
      await this.procedimentoService
        .getProcedimentosPorUnidadeEprocedimento(
          this.unidadeId,
          this.tipoProcedimentoId
        )
        .then(async (response) => {
          console.log("response");
          console.log(response);
          this.procedimentosList = response.map((procedimento) => {
            return {
              value: {
                procedimentoId: procedimento.procedimento_id,
                convenio_procedimento_id: procedimento.convenio_procedimento_id,
              },
              descricao: `${procedimento.procedimento_descricao} | ${procedimento.tipo_convenio_descricao}`,
            };
          });
        })
        .finally(() => {
          console.log("this.procedimentosList");
          console.log(this.procedimentosList);
        });
    },
    async getTipoProcedimentos() {
      await this.tipoProcedimentoService
        .getTipoProcedimentos()
        .then(async (response) => {
          const { data } = await response.json();
          if (this.perfilId == 3) {
            this.tipoProcedimentoList = [];
            this.tipoProcedimentoList = [
              { tipoProcedimentoId: 4, tipoProcedimentoDescricao: "Exame" },
              { tipoProcedimentoId: 5, tipoProcedimentoDescricao: "Medicação" },
            ];
          } else {
            this.tipoProcedimentoList = data.tipo_procedimentos.map(
              (tipoProcedimento) => {
                return {
                  tipoProcedimentoId: tipoProcedimento.id,
                  tipoProcedimentoDescricao: tipoProcedimento.descricao,
                };
              }
            );
          }
        })
        .finally(() => {});
    },
    async getFormularioByProcedimentoId() {
      await this.formularioService.getFormularioByProcedimentoId(
        {
          onSucess: (status) => (response) => {
            if (status === 200) {
              this.formulariosList = response.data.map((formulario) => {
                return {
                  id: formulario.id_formulario,
                  descricao: formulario.descricao,
                };
              });
            }
          },
          onError: (error) => {
            // this.$emit("close", { error });
            console.log(error);
          },
          onEnd: () => {},
        },
        this.procedimentoId.procedimentoId
      );
    },
    async postNewFormulario() {
      console.log("this.model()");
      console.log(this.model());
      // this.dialogNovaConsulta = true;
      // 'method' => 'POST',  'url' => 'anamnese/adicional', - botão Anamnese Adcional executa essa url
      await this.formularioService.postNewFormulario(
        {
          onSucess: (status) => (response) => {
            if (status === 200) {
              console.log("response");
              console.log(response);
              this.id_agendamento = response.data.agendamento.id_agendamento;
              this.id_requisicao = response.data.requisicao.id_requisicao;
              this.dialogNovaConsulta = true;
            }
          },
          onError: (error) => {
            this.$emit("close", { error });
            console.log(error);
          },
          onEnd: () => {
            this.$emit("setAnamneseCriada");
          },
        },
        this.model()
      );
    },
    validarForm() {
      if (
        this.procedimentoId != 0 &&
        this.tipoProcedimentoId != 0 &&
        this.formularioId != 0
      ) {
        this.disabledBtn = false;
      } else {
        this.disabledBtn = true;
      }
    },
    concluir() {
      console.log("teste Final");
      this.$emit("close");
    },
    model() {
      return {
        idPet: this.petId,
        idTutor: this.tutorId,
        idUnidade: this.unidadeId,
        idProcedimento: parseInt(this.procedimentoId.procedimentoId),
        idConvenioProcedimento: parseInt(
          this.procedimentoId.convenio_procedimento_id
        ),
        idFormulario: parseInt(this.formularioId),
      };
    },
  },
};
</script>

<style></style>
