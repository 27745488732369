<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Medicina com Carinho</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <v-container>
      <div class="container my-5">
        <div class="row">
          <div class="col-10">
            <v-text-field
              v-model="numeroCartao"
              :type="'text'"
              label="Informe Número do Cartão "
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <v-text-field
              v-model="cpfTitular"
              :type="'text'"
              v-mask="'###.###.###-##'"
              label="Informe CPF Titular"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button
              :disabled="numeroCartao == ''"
              @click.prevent="cadastrarMedicinaCarinho"
              class="btn btn-app-primary btn-rounded font-weight-bold"
            >
              <span v-if="!loading"> Cadastrar </span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </button>
          </div>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import MedicinaCarinhoService from "@/services/medicina_carinho_service.js";

export default {
  mixins: [Mixin],
  components: {},
  props: {
    infos: {
      type: Object,
      // required: true,
      required: false,
    },
  },
  data() {
    return {
      numeroCartao: "",
      cpfTitular: "",
      services: {
        medicinaCarinhoService: MedicinaCarinhoService.build(),
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    cadastrarMedicinaCarinho() {
      this.services.medicinaCarinhoService.postCadastrarCartao(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "sucess",
              });
              this.$emit("close");
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
            // this.$emit("pago");
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {},
        },
        {
          id_pet: parseInt(this.infos.id_pet),
          numero_cartao: parseInt(this.numeroCartao),
          cpf: this.cpfTitular.replaceAll(".", "").replaceAll("-", ""),
        }
      );
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
