<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Cadastrar Setor</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-12 form-group clearfix mb-3">
          <label for="nome">Descrição do Setor</label>
          <v-text-field
            v-model="setorDescricao"
            placeholder="Descrição do Setor"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-3">
          <label>Centro de Custo</label>
          <v-select
            style="padding-top: 0 !important"
            :items="centroCustoList"
            v-model="centroCustoId"
          />
        </div>
        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrar"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import SetorService from "../../services/setor_service";
import CentroCustoService from "../../services/centro_custo_service";

export default {
  mixins: [Mixin],
  data: () => ({
    formCadastro: "",
    setorDescricao: "",
    centroCustoList: [],
    centroCustoId: 0,
    loading: false,
    services: {
      setorService: SetorService.build(),
      centroCustoService: CentroCustoService.build(),
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  mounted() {
    this.formCadastro = document.forms[0];
    this.setCentroCustoList();
  },
  methods: {
    cadastrar() {
      this.loading = true;
      this.services.setorService
        .register(this.getFormData())
        .then(({ status }) => {
          this.loading = false;
          this.mostrarFeedback(status);
          if (status === 200) {
            this.resetForm();
            this.$emit("response");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setCentroCustoList() {
      await this.services.centroCustoService
        .getCentroCustoPaginatedList({
          page: null,
          per_page: null,
          sort: null,
          desc: null,
          search: null,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { centro_custo } = data;
          console.log({ centro_custo });
          this.centroCustoList = centro_custo
            .filter((c) => !c.desativado_por)
            .map(({ id, descricao }) => {
              return {
                value: id,
                text: descricao,
              };
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    mostrarFeedback(status) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status === 200
            ? "Setor cadastrado com sucesso!"
            : status === 400
            ? "Preencha os dados Corretamente"
            : this.$global.messages.internalServerError,
        color: status === 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.setorDescricao = "";
    },
    getFormData() {
      const formData = new FormData();
      formData.append("descricao", this.setorDescricao);
      formData.append("id_centro_custo", this.centroCustoId);
      return formData;
    },
  },
};
</script>
