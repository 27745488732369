var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),(_vm.mobile)?_c('v-template',{staticClass:"d-block d-sm-none"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#788dfb ","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-information")]),_vm._v(" Legenda ")],1)]}}],null,false,1000556866)},[_c('v-list',_vm._l((_vm.legendas),function(legenda,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('div',{staticClass:"d-flex align-item-center"},[_c('div',{class:("dot-legenda " + (legenda.dot)),staticStyle:{"margin":"0px 10px"}},[_vm._v(" "+_vm._s(legenda.number)+" ")]),_c('span',{class:("style-text " + (legenda.font))},[_vm._v(" "+_vm._s(legenda.descricao)+" ")])])])],1)}),1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mb-2 mt-5",attrs:{"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] },"search":_vm.search,"headers":_vm.headers,"items":_vm.consultas,"options":_vm.options,"server-items-length":_vm.totalList,"loading":_vm.loading,"loading-text":'Carregando...',"mobile-breakpoint":"350"},on:{"update:sort-by":function($event){_vm.options.ordenacao = true},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status_sala",fn:function(ref){
var item = ref.item;
return [_c('v-template',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(item.status_sala ? item.status_sala : item.idConsulta ? "Atendido" : "Agendado")+" ")]),_c('v-template',{staticClass:"d-block d-sm-none"},[_c('div',{staticClass:"dot-legenda",class:_vm.tipoDotConsulta(item.status_sala, item.idConsulta)},[_vm._v(" "+_vm._s(_vm.numberDotConsulta(item.status_sala, item.idConsulta))+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.isCancelado)?_c('div',[_c('div',{staticClass:"col-6 text-left"},[(item.isConfirmado)?_c('button',{staticClass:"btn btn-app-primary btn-rounded font-weight-bold"},[_c('span',[_vm._v(" Acessar ")])]):_vm._e()]),(!item.isConfirmado)?_c('v-row',{staticClass:"mb-2"},[(item.isCancelado)?_c('button',{staticClass:"btn btn-app-primary btn-rounded font-weight-bold",on:{"click":function($event){return _vm.showCancelarConsulta(item)}}},[_vm._v(" "+_vm._s(item.isCancelado)+" "),_c('span',[_vm._v(" Cancelar ")])]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogCancelarConsulta),callback:function ($$v) {_vm.dialogCancelarConsulta=$$v},expression:"dialogCancelarConsulta"}},[_c('v-card',[(!_vm.loadingCancelar)?_c('div',[_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"normal"}},[_vm._v("Tem certeza de que deseja desmarcar a consulta? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#1daf80","text":""},on:{"click":_vm.closeCancelarConsulta}},[_vm._v(" Não ")]),_c('v-btn',{staticClass:"btn-danger",staticStyle:{"color":"#fff !important"},attrs:{"text":""},on:{"click":_vm.cancelarConsulta}},[_vm._v(" Sim ")]),_c('v-spacer')],1)],1):_c('v-card-actions',[_c('v-spacer'),_c('v-spacer'),_c('v-row',[_c('v-progress-circular',{staticClass:"py-5 my-5",attrs:{"size":70,"width":5,"indeterminate":"","color":"green"}})],1),_c('v-spacer')],1)],1)],1),(item.statusMeeting != null)?_c('button',{staticClass:"btn btn-app-primary btn-rounded font-weight-bold m-0",on:{"click":function($event){return _vm.getMeeting(item)}}},[_c('span',[_vm._v(" Acessar Meeting ")])]):_vm._e()],1):_vm._e()],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v("Nenhum registro encontrado")])]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-lg-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 title-style"},[_c('h3',[_vm._v(" Histórico Consultas Televet Play ")])])])])}]

export { render, staticRenderFns }