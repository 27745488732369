<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Raças</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="racas"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="nome"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                color="#1daf80"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nova Raça
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarRacaPosCadastro($event)"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          />
          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal;"
                >Tem certeza de que deseja excluir esta Raça?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#1daf80" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :loading="loadingDelete"
                  class="btn-danger"
                  text
                  style="color: #fff !important;"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- DIALOG EDIÇÃO -->
          <v-dialog v-if="dialogEdicao" v-model="dialogEdicao">
            <modal-edicao
              v-if="dialogEdicao"
              :racaId="editedRaca"
              @close="dialogEdicao = false"
              @response="atualizarRacaPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon v-if="allowDeletarBtn" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#1daf80" dark @click="atualizarRacas">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalCadastro from "@/components/racas/modal_cadastro";
import modalEdicao from "@/components/racas/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";
import RacaService from "../services/raca_service";

export default {
  mixins: [Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedRaca: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      { text: "Raça", align: "start", sortable: false, value: "nome" },
      {
        text: "Espécie",
        align: "start",
        sortable: false,
        value: "especieDescricao",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    racas: [],
    loading: false,
    loadingDelete: false,
    services: {
      authService: AuthService.build(),
      racaService: RacaService.build(),
    },
    racaIdToDelete: 0,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarRacas();
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarRacas();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarRacas();
      }
    },
  },

  mounted() {
    this.atualizarRacas();
  },
  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints["RacaController::POST->registerRaca"]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints["RacaController::POST->updateRaca/$1"]
      );
    },
    allowDeletarBtn() {
      return this.services.authService.temPermissao(
        endpoints["RacaController::POST->deleteRaca/$1"]
      );
    },
  },
  methods: {
    atualizarRacaPosCadastro() {
      this.atualizarRacas();
      this.closeDialogCadastrar();
    },
    atualizarRacaPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarRacas();
    },
    async atualizarRacas() {
      this.loading = true;
      const response = await this.services.racaService.getRacaPaginatedList({
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        search: this.search,
        sort: this.options.ordenacao,
        desc: this.options.desc,
      });
      const { racas, pagination } = response;
      this.totalList = pagination.num_rows;
      this.racas = racas;
      this.loading = false;
    },
    async editItem(item) {
      this.editedRaca = item.racaId || 0;
      // JSON.stringify(item)
      // )
      // const body = await this.services.racaService.getDetalhesByRacaId(item.racaId);
      // if (!body) return;
      // this.editedRaca = body;
      this.dialogEdicao = true;
    },

    deleteItem({ racaId }) {
      console.log({ racaId });
      // this.editedRaca = JSON.parse(JSON.stringify({racaId}))
      this.racaIdToDelete = racaId;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.loadingDelete = true;
      const success = await this.services.racaService.deletarRaca(
        this.racaIdToDelete
      );
      this.loadingDelete = false;
      if (success) {
        this.atualizarRacas();
        this.$_ACTIONS_showSnackbarMessage({
          message: "O raca foi deletado com sucesso.",
          color: "sucess",
        });
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message:
            "A raça não pôde ser deletada. Provavelmente, já existe um pet com essa raça já cadastrado no sistema.",
          color: "error",
        });
      }
      this.closeDelete();
    },

    closeDialogCadastrar() {
      console.log("closeDialogCadastrar");
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
