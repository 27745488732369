import HTTPService from "@/services/http_service.js";

export default class AgendaEspecialidadeService extends HTTPService {
  resource = "agenda/unidade/especialidades";
  resource2 = "agenda/especialidade/unidade";
  resource3 = "agendamento/recepcao/especialidade";
  resource4 = "especialidade-procedimento/unidade/especialidade";
  resource5 = "agenda/especialidade/unidade-televet";
  resource6 = "agendamento/televet/especialidade";

  static build() {
    return new AgendaEspecialidadeService();
  }

  getAgendaEspecialidadeByUnidadeId(unidadeId) {
    return this.get(`${this.resource}/${unidadeId}`);
  }
  getAgendaEspecialidadebyRecepcao(unidadeId, especialidadeId, dataPesquisa) {
    dataPesquisa = dataPesquisa.replaceAll("-", "");
    return this.get(
      `${this.resource2}/${especialidadeId}/${unidadeId}/${dataPesquisa}`
    );
  }

  getAgendaEspecialidadebyTelevet(unidadeId, especialidadeId, dataPesquisa) {
    dataPesquisa = dataPesquisa.replaceAll("-", "");
    return this.get(
      `${this.resource5}/${especialidadeId}/${unidadeId}/${dataPesquisa}`
    );
  }
  getAgendaEspecialidadeUnidadebyDay(unidadeId, especialidadeId) {
    return this.get(
      `agenda/especialidade/unidade-deskvet/${especialidadeId}/${unidadeId}`
    );
  }

  getProcesamentobyUnidadeEspecialidade(unidadeId, especialidadeId) {
    return this.get(`${this.resource4}/${unidadeId}/${especialidadeId}`);
  }
  postAgendamentoRecepcao(model) {
    return this.post(this.resource3, { ...model });
  }
  postAgendamentoTelevet(model) {
    return this.post(this.resource6, { ...model });
  }
  postAgendamentoRecepcaoUpdate(model) {
    return this.post(`${this.resource3}/alterar`, { ...model });
  }
  getHorariosDisponiveisFromDate(responseFunctions, options) {
    this.request(
      responseFunctions,
      "GET",
      this.resource + "/disponivel/date",
      null,
      options
    );
  }
  postReagendarAtendimento(responseFunctions, request) {
    this.request(
      responseFunctions,
      "POST",
      "reagendar/unidade/especialidades",
      request
    );
  }
  postDesmarcarAtendimento(responseFunctions, request) {
    this.request(
      responseFunctions,
      "POST",
      "desmarcar/unidade/especialidades",
      request
    );
  }
  postConfirmarAgendamento(model) {
    return this.post("agendamento/televet/confirmar", { ...model });
  }
}
