import HTTPService from "@/services/http_service.js";

export default class PagamentoService extends HTTPService {
  resource = "loja/compra/pagsegurocartao";
  resourcePagSeg = "https://sandbox.api.pagseguro.com";

  static build() {
    return new PagamentoService();
  }
  /**
        @returns {Promise<Response>}
     */

  postPagamento(responseFunctions, model) {
    this.jsonRequest(responseFunctions, "POST", `${this.resource}/new`, model);
  }

  getPublicKey() {
    return this.get(`pagseguro/key`);
  }
}
