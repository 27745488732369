<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Prontuários</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div v-if="!step">
        <v-card-text>
          <v-container>
            <v-expansion-panels v-model="panel">
              <div
                class="row mx-auto mb-4 py-6 px-4"
                v-if="atendimento.idConsulta == null"
              >
                <div
                  class="col mx-auto mb-4 py-6 px-4"
                  style="max-width: 300px"
                >
                  <v-row justify="center">
                    <v-select
                      v-model="formularioEditarAtendimento"
                      item-text="titulo"
                      item-value="id"
                      :items="formularios"
                      :loading="loadingFormularios"
                      label="Tipo de formulários"
                    ></v-select>
                  </v-row>
                </div>

                <v-row class="mt-6" justify="center">
                  <v-btn
                    v-if="formularioEditarAtendimento !== null"
                    color="green darken-4"
                    dark
                    class="mt-3"
                    @click="step = true"
                  >
                    Iniciar atendimento
                  </v-btn>
                </v-row>
              </div>

              <v-row class="mb-4 ml-2">
                <v-icon
                  class="mr-2"
                  title="Galeria de fotos"
                  @click="listGalerias(item)"
                  >fas fa-images
                </v-icon>
                <v-icon
                  class="mr-2"
                  title="Fotos classificadas"
                  @click="listFotosClassificadas(item)"
                  >fa-solid fa-bookmark
                </v-icon>
              </v-row>

              <v-expansion-panel
                @click="formularioEditar = item"
                v-for="(item, i) in consultas"
                :key="i"
              >
                <v-expansion-panel-header v-slot="{ open }">
                  <v-row no-gutters>
                    <v-col cols="4" class="align-self-center">
                      Data: {{ item.data | data }}
                    </v-col>
                    <v-col cols="8" class="text--secondary">
                      <v-fade-transition leave-absolute>
                        <span v-if="open" key="0" class="d-flex">
                          <v-col cols="10" class="align-self-center">
                            Procedimento:{{ item.procedimento }}
                          </v-col>
                        </span>
                        <span v-else key="1" class="d-flex">
                          <v-col cols="7" class="align-self-center">
                            {{ item.procedimento }}
                          </v-col>
                          <v-col cols="5">
                            Há {{ calcular_tempo(item) }}
                          </v-col>
                        </span>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template>
                    <v-card elevation="0">
                      <v-tabs color="green darken-4">
                        <v-tab>
                          <h5>Resumo</h5>
                        </v-tab>

                        <!-- RESUMO -->
                        <v-tab-item>
                          <v-card flat :loading="loading">
                            <v-card-text>
                              <v-row
                                no-gutters
                                class="text-left flex-column"
                                v-if="!loading"
                              >
                                <v-row class="justify-end">
                                  <v-btn
                                    color="green darken-3"
                                    small
                                    @click="editar = !editar"
                                  >
                                    <v-icon v-if="editar" color="#ffffff">
                                      mdi-pencil
                                    </v-icon>
                                    <v-icon v-else color="#ffffff">
                                      mdi-close
                                    </v-icon>
                                  </v-btn>
                                </v-row>
                                <v-row class="flex-column">
                                  <label for="resumo"
                                    ><strong>Resumo: </strong>
                                  </label>
                                  <v-text-field
                                    :disabled="editar"
                                    id="resumo"
                                    v-model="consulta.consulta_descricao"
                                  ></v-text-field>
                                </v-row>
                                <v-row class="flex-column">
                                  <div
                                    v-for="(pergunta,
                                    index) in consulta.perguntas"
                                    :key="index"
                                  >
                                    <consulta-fill
                                      @rchange="atributeModel"
                                      :pergunta="pergunta"
                                      :disabled="!editar"
                                    />
                                  </div>
                                </v-row>
                                <v-row class="flex-column">
                                  <v-col
                                    ><v-btn
                                      color="green darken-3"
                                      @click="atualizarProntuario"
                                      v-if="!editar"
                                    >
                                      <v-icon color="#ffffff">
                                        mdi-pencil
                                      </v-icon>
                                      <span style="color: #ffffff"
                                        >Salvar alterações</span
                                      >
                                    </v-btn></v-col
                                  >
                                </v-row>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
      </div>

      <v-dialog
        v-else
        v-model="step"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <modal-iniciar-consulta
          @close="close"
          @reloadConsultas="reloadConsultas"
          :paciente="`${this.atendimento.paciente_id}`"
          :info="getAgendamentoFormulario"
        />
      </v-dialog>
      <!-- DIALOG DE PRONTUÁRIO -->
      <v-dialog
        v-model="dialogGaleria"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <galerias
          :paciente="getPaciente"
          :key="rederComp"
          @close="closeModal()"
        />
      </v-dialog>
      <v-dialog
        v-model="dialogFotosClassificadas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <fotos-classificadas
          :paciente="`${this.atendimento.paciente_id}`"
          :key="rederComp"
          @close="closeModal()"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
// import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import ConsultaFill from "@/components/prontuarios/consulta_campo_fill.vue";
import ConsultaService from "@/services/consulta_service.js";
import ModalIniciarConsulta from "@/components/consultas/modal_iniciar_consulta";
import Galerias from "@/components/prontuarios/galeria.vue";
import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  components: {
    Galerias,
    FotosClassificadas,
    ConsultaFill,
    ModalIniciarConsulta,
    // FotosClassificadas,
  },

  props: {
    consultas: Array,
    formularios: Array,
    atendimento: Object,
  },
  beforeMount() {
    // console.log("consultas")
    // console.log(this.consultas)
  },
  data: () => ({
    rederComp: 1,
    step: false,
    consulta: {},
    panel: [],
    loading: true,
    open: false,
    date: null,
    editar: true,
    trip: {
      name: "",
      location: null,
      start: null,
      end: null,
    },
    formularioEditar: null,
    formularioEditarAtendimento: null,
    plc: {},
    dialogGaleria: false,
    dialogFotosClassificadas: false,
  }),

  computed: {
    getPaciente() {
      const paciente = {
        paciente_id: this.atendimento.paciente_id,
        id_paciente: this.atendimento.paciente_id,
        nomePaciente: this.atendimento.nomePaciente,
      };

      return paciente;
    },

    getAgendamentoFormulario() {
      const agendamentoFormulario = {
        idAgendamentoSelecionado: this.atendimento.idAgendamento,
        idFormulario: this.formularioEditarAtendimento,
      };

      return agendamentoFormulario;
    },
  },
  watch: {
    panel(index) {
      if (index >= 0) {
        this.carregar_item(index);
      } else {
        this.consulta = {};
      }
    },
  },

  methods: {
    atributeModel(change) {
      const newValue = change.alteracao;
      const perguntaId = change.pergunta.id;

      const pergunta = this.consulta.perguntas.find((e) => e.id === perguntaId);
      const respostaCampos = {
        1: "resposta_pergunta_textual",
        2: "resposta_pergunta_textual",
        3: "resposta_pergunta_opcao",
        4: "resposta_pergunta_opcao",
      };
      const respostaCampo = respostaCampos[pergunta.id_tipo_pergunta];
      pergunta[respostaCampo] = newValue;
    },
    reloadConsultas() {
      this.$emit("reloadConsultas");
      this.atendimento.idConsulta = -1;
    },
    atualizarProntuario() {
      let formulario = this.formularioEditar;

      formulario.id_consulta = Number.parseInt(
        this.formularioEditar.id_consulta
      );
      formulario.titulo = this.formularioEditar.consulta_titulo;
      formulario.descricao = this.consulta.consulta_descricao;
      formulario.id_formulario = this.consulta.formulario_id;

      formulario.respostas = this.consulta.perguntas.map((resposta) => {
        if (resposta.resposta_pergunta_textual) {
          resposta.resposta = resposta.resposta_pergunta_textual;
        }

        if (resposta.resposta_pergunta_decimal) {
          resposta.resposta = resposta.resposta_pergunta_decimal;
        }

        if (resposta.resposta_pergunta_inteira) {
          resposta.resposta = resposta.resposta_pergunta_inteira;
        }

        if (resposta.resposta_pergunta_opcao) {
          resposta.resposta = resposta.resposta_pergunta_opcao;
        }

        resposta.id_pergunta = resposta.id;

        return resposta;
      });

      this.plc = this.consulta;

      const consultaService = new ConsultaService();
      consultaService.update(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.form_resp = {};
              this.resp = [];
              this.$_ACTIONS_showSnackbarMessage({
                message: "Alterações salvas com sucesso",
                color: "sucess",
              });
              // this.$emit("finalizarConsulta", { msg:body.message, cor:"sucess", nome: this.paciente.nome} );
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loadingNovoFormulario = false;
          },
        },
        formulario,
        formulario.id_consulta
      );
    },

    forceRender() {
      this.rederComp += 1;
    },

    listGalerias(item) {
      this.paciente = item;
      this.dialogGaleria = true;
      this.forceRender();
    },

    listFotosClassificadas() {
      this.paciente = this.$_GETTERS_usuario.idPaciente;
      this.dialogFotosClassificadas = true;
      this.forceRender();
    },

    close() {
      this.step = false;
      this.$emit("close");
    },

    calcular_tempo(i) {
      var data = new Date(i.data);
      var hj = new Date();
      var dif = hj.getTime() - data.getTime();
      var dias = dif / (1000 * 3600 * 24);
      dias = Math.floor(dias);
      var resp = "";
      if (dias > 8) {
        var semanas = Math.floor(dias / 7);
        dias -= semanas * 7;
        resp = `${semanas} sem. e ${dias} dia(s) `;
        if (semanas > 4) {
          var meses = Math.floor(semanas / 4);
          semanas -= meses * 4;
          resp = `2 anos , ${meses} m., ${semanas} sem. e ${dias} dia(s) `;
          if (meses > 12) {
            var anos = Math.floor(meses / 12);
            meses -= anos * 12;
            resp = `${meses} ano(s) , ${meses} m., ${semanas} sem. e ${dias} dia(s) `;
          }
        }
      } else {
        resp = dias + " dia(s)";
      }
      return resp;
    },
    carregar_item(index) {
      const consultaService = new ConsultaService();
      consultaService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.consulta = body.data;
              console.log("this.consulta.perguntas");
              console.log(this.consulta.perguntas);
              console.log(this.consulta);
              console.log("this.consulta.perguntas");
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.consultas[index].id_consulta
      );

      //  setTimeout(() =>  this.loading = false, 1000);
      //     this.consulta = this.consultas[index]
    },
    sem_fotos_para_classificar() {
      this.loadingClassificarFotos = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: "Não coseguimos encontrar as fotos",
        color: "error",
      });
    },
    sem_fotos() {
      this.loadingFotos = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: "Não coseguimos encontrar as fotos",
        color: "error",
      });
    },
    closeModal() {
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
    },
  },
};
</script>
