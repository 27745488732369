<template>
  <div class="text-left py-4 px-5">
    <h4 class="text-center">
      Contrato de Serviços de Teleorientação Veterinária
    </h4>
    <p>
      Este Contrato de Serviços de Teleorientação Veterinária (doravante
      denominado "Contrato") é celebrado entre as Partes (assim referidas
      coletivamente) a seguir qualificadas.
    </p>
    <p>
      <font class="negrito">1. DA QUALIFICAÇÃO</font><br />
      <font class="negrito sublinhado">TELEVETPLAY LTDA.</font>, pessoa jurídica
      inscrita no CNPJ/MF sob o n° 49.583.642/0001-50, estabelecida na Av.
      Rebouças, 2437, Pinheiros, São Paulo, SP, CEP 05401-300, doravante
      denominada CONTRATADA.<br />
      <font class="negrito sublinhado">RESPONSÁVEL</font>, devidamente
      qualificado no ato da contratação, pelo BENEFICIÁRIO, cão ou gato também
      devidamente identificado no ato da contratação, doravante denominado
      CONTRATANTE.<br />
      Considerando que a CONTRATADA é especializada na prestação de serviços de
      teleorientação veterinária, conforme definido pela Resolução do Conselho
      Federal de Medicina Veterinária (CFMV) nº 1.465/2022, que incluem
      orientações veterinárias remotas; Considerando que a CONTRATANTE está
      interessada em utilizar os serviços de teleorientação veterinária
      oferecidos pela CONTRATADA;<br />
      As Partes, de comum acordo, têm entre si justo e acertado o presente
      Contrato de Serviços de Teleorientação Veterinária que se regerá pelas
      seguintes cláusulas e condições.
    </p>
    <p>
      <font class="negrito">2. DA DESCRIÇÃO DOS SERVIÇOS</font><br />
      2.1. A CONTRATADA fornecerá à CONTRATANTE serviços de teleorientação
      médico-veterinária, de acordo com as definições e vedações estabelecidas
      pela Resolução do Conselho Federal de Medicina Veterinária (CFMV) nº
      1.465/2022.<br />
      2.2. A teleorientação médico-veterinária compreende a modalidade de
      telemedicina veterinária definida pela Resolução CFMV nº 1.465/2022 e
      abrange exclusivamente a prestação de orientações médicos-veterinários
      gerais e iniciais, à distância, por meio de tecnologias de comunicação
      remotas.<br />
      2.3. A teleorientação médico-veterinária está sujeita às vedações
      previstas na Resolução CFMV nº 1.465/2022, sendo expressamente proibido o
      fornecimento de diagnósticos, conduta terapêutica, prescrições,
      solicitação de exames e qualquer prescrição.<br />
      2.4. A CONTRATANTE reconhece que a teleorientação médico-veterinária
      possui limitações em comparação com uma consulta veterinária presencial,
      especialmente no que diz respeito à impossibilidade de realizar exames
      físicos detalhados e outros procedimentos médico-veterinários. Parágrafo
      único: A CONTRATANTE concorda em utilizar os serviços fornecidos pela
      CONTRATADAde acordo com essas limitações e compreende que a teleorientação
      médico-veterinária tem como objetivo fornecer orientações
      médicos-veterinários gerais e iniciais, não substituindo a consulta
      veterinária presencial quando necessária.<br />
      2.5. A CONTRATADA se compromete a fornecer as orientações veterinárias
      durante a teleorientação médicoveterinária de acordo com as melhores
      práticas veterinárias e dentro dos padrões e regulamentos estabelecidos
      pelas autoridades veterinárias competentes.<br />
      2.6. A CONTRATANTE reconhece que a CONTRATADA não realizará diagnósticos,
      conduta terapêutica, prescrições, solicitação de exames e qualquer
      prescrição durante a teleorientação médico-veterinária., e que tais
      serviços poderão ser recomendados durante a consulta veterinária
      presencial para o benefício da saúde do animal de estimação.
    </p>
    <p>
      <font class="negrito">3. DAS RESPONSABILIDADES DA CONTRATANTE</font><br />
      3.1. A CONTRATANTE é responsável por fornecer informações precisas,
      completas e atualizadas sobre a condição de saúde do seu animal de
      estimação, histórico médico veterinário e quaisquer outros dados
      relevantes para a prestação adequada dos serviços de teleorientação
      veterinária.<br />
      3.2. A CONTRATANTE reconhece que é sua responsabilidade garantir que todas
      as informações fornecidas à CONTRATADA sejam verdadeiras e precisas. A
      CONTRATADA não se responsabiliza por quaisquer consequências decorrentes
      de informações imprecisas ou omitidas pela CONTRATADA.<br />
      3.3. A CONTRATANTE deve seguir as orientações e recomendações fornecidas
      pela CONTRATADA durante a teleorientação veterinária.
    </p>
    <p>
      <font class="negrito">4. DA PRIVACIDADE E CONFIDENCIALIDADE </font><br />
      4.1. A CONTRATADA se compromete a tratar todas as informações fornecidas
      pela CONTRATADA como confidenciais e a cumprir todas as leis e
      regulamentos aplicáveis à proteção de dados e privacidade.<br />
      4.2. A CONTRATANTE concorda que a CONTRATADA pode coletar, armazenar e
      processar suas informações pessoais e dados veterinários, conforme
      necessário para a prestação dos serviços de teleorientação veterinária.<br />
      4.3. A CONTRATADA tomará medidas razoáveis para proteger as informações da
      CONTRATADA contra acesso não autorizado, uso indevido, divulgação ou
      alteração.
    </p>
    <p>
      <font class="negrito">5. DAS CONDIÇÕES DE PAGAMENTO E REAJUSTE </font
      ><br />
      5.1. Os valores pelos serviços de teleorientação veterinária serão
      estabelecidos pela CONTRATADA e comunicados à CONTRATANTE antes da
      utilização dos serviços.<br />
      5.2. A CONTRATANTE concorda em pagar os valores devidos pelos serviços de
      teleorientação veterinária de acordo com as políticas de pagamento
      estabelecidas pela CONTRATADA.<br />
      5.3. A CONTRATADA se reserva o direito de incluir ou excluir meios de
      pagamento ou instituições financeiras para realização dos pagamentos.<br />
      5.4. Os valores serão reajustados anualmente, com base no ano-calendário,
      por meio dos seguintes critérios:<br />
      a. financeiro: pelo percentual obtido na variação do IGP-M (Índice Geral
      de Preços – Mercado).<br />
      b. técnico: mediante a variação dos custos assistenciais em medicina
      veterinária.<br />
      Parágrafo primeiro: O reajuste será aplicado no início de cada
      ano-calendário subsequente, a partir de 1º de janeiro.<br />
      Parágrafo segundo: Caso o índice de referência escolhido seja extinto ou
      deixe de ser disponibilizado, as partes concordam em utilizar um índice
      similar que seja amplamente reconhecido e adequado às condições econômicas
      vigentes.
    </p>
    <p>
      <font class="negrito">6. LIMITAÇÃO DE RESPONSABILIDADE </font><br />
      6.1. A CONTRATADA não se responsabiliza por qualquer dano, perda ou lesão
      decorrente da utilização dos serviços de teleorientação veterinária,
      exceto nos casos em que tal dano, perda ou lesão seja diretamente causado
      por negligência grave ou conduta intencional da CONTRATADA.<br />
      6.2. A CONTRATADA não oferece garantias ou representações em relação aos
      resultados obtidos por meio da utilização dos serviços de teleorientação
      veterinária. A CONTRATADA não garante que a teleorientação veterinária
      seja apropriada para todos os casos ou que os serviços atenderão a todas
      as necessidades da CONTRATADA.
    </p>
    <p>
      <font class="negrito"
        >7. DO ACESSO À REDE MUNDIAL DE COMPUTADORES (INTERNET)</font
      ><br />
      7.1. A CONTRATANTE reconhece que a CONTRATADA não é responsável por
      fornecer meios de acesso à internet para a utilização dos serviços
      contratados.<br />
      7.2. A CONTRATANTE será responsável por obter e manter seu próprio acesso
      à internet, incluindo todos os equipamentos, conexões e serviços
      necessários para se conectar à rede mundial de computadores.<br />
      7.3. A CONTRATADA não será responsável por quaisquer problemas, falhas ou
      interrupções relacionadas ao acesso à internet da CONTRATANTE, incluindo,
      mas não se limitando a, velocidade de conexão, estabilidade, segurança ou
      disponibilidade.<br />
      7.4. Qualquer suporte técnico relacionado ao acesso à internet será de
      responsabilidade exclusiva da CONTRATANTE, e a CONTRATADA não terá
      qualquer obrigação de fornecer assistência nesse sentido.<br />
      7.5. A CONTRATANTE declara estar ciente de que a ausência de acesso à
      internet poderá afetar a utilização dos serviços contratados, e essa
      responsabilidade é exclusivamente da CONTRATANTE.
    </p>
    <p>
      <font class="negrito"
        >8. DA VIGÊNCIA, CANCELAMENTO, EXTINÇÃO E RENOVAÇÃO</font
      ><br />
      8.1. O prazo do presente Contrato é celebrado de acordo com a opção
      selecionada pela CONTRATANTE no ato da contratação:<br />
      a. na opção “Teleorientação Avulsa”, o prazo findar-se-á no término do
      atendimento.<br />
      b. na opção “Plano Anual”, o prazo será de 12 (doze) meses.<br />
      Parágrafo único: A CONTRATANTE concorda que, ao optar pela modalidade de
      “Teleorientação Avulsa”, o presente Contrato terá vigência exclusivamente
      para o atendimento específico contratado, não se estendendo além desse
      escopo.<br />
      8.2. A vigência do presente Contrato terá início na data de confirmação do
      pagamento.<br />
      8.3. A extinção do presente Contrato dá-se ao término da vigência.<br />
      8.4. Em caso de cancelamento do presente Contrato, por qualquer motivo, o
      montante vincendo será restituído descontado:<br />
      a. os serviços utilizados.<br />
      b. a taxa de parcelamento.<br />
      c. toda e qualquer outra taxa decorrente ou incidente sobre o presente
      Contrato. Parágrafo primeiro: O cancelamento deverá ser solicitado pela
      CONTRATANTE por meio do e-mail sac@televetplay.com.br.<br />
      Parágrafo segundo: O cancelamento somente se efetivará após autorização
      por escrito da CONTRATANTE. 8.5. O presente Contrato poderá a qualquer
      momento ser extinto por iniciativa da CONTRATADA, nos seguintes casos:<br />
      a. com aviso prévio de 30 (dias) à CONTRATANTE.<br />
      b. quando haja o inadimplemento por prazo superior a 60 (sessenta)
      dias.<br />
      Parágrafo primeiro: Em caso de inadimplemento, além dos descontos
      descritos na Cláusula 8.4, haverá a incidência de multa não compensatória
      de 10% (dez por cento), juros de 1% (um por cento), correção monetária e
      10% (dez por cento) de honorários advocatícios sobre o montante das
      parcelas vincendas, e ainda, o respectivo protesto perante os órgãos de
      proteção ao crédito.<br />
      8.6. A CONTRATANTE concorda que, ao optar pela modalidade de “Plano
      Anual”, o presente Contrato será renovado automaticamente por prazo
      indeterminado.<br />
      Parágrafo único: Em caso de renovação automática, as disposições
      contratuais continuarão plenamente em vigor, mantendo-se a validade e
      eficácia das obrigações e direitos estabelecidos, a menos que modificados
      por acordo escrito entre as partes.<br />
      8.7. A CONTRATANTE concorda que, ao optar pela modalidade de
      “Teleorientação Avulsa”, o presente Contrato não será renovado
      automaticamente por prazo indeterminado.
    </p>
    <p>
      <font class="negrito">9. DISPOSIÇÕES GERAIS</font><br />
      9.1. Este Contrato constitui o entendimento completo entre as Partes em
      relação aos serviços de teleorientação veterinária e substitui todos os
      acordos anteriores ou contemporâneos, verbais ou escritos, em relação ao
      assunto aqui tratado.<br />
      9.2. Qualquer modificação ou alteração do presente Contrato deve ser feita
      por escrito e assinada por ambas as Partes.<br />
      9.3. Qualquer tolerância pelas partes em relação às cláusulas e condições
      do presente Contrato ou mesmo o retardamento da exigibilidade de direitos,
      não importará em precedente, novação, moratória ou alteração, permanecendo
      todos os termos do presente Contrato plenamente exigíveis e exequíveis.<br />
      9.4. Qualquer cláusula ou condição do presente Contrato que, por qualquer
      razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou
      tribunal, não afetará a validade das demais disposições contratuais, as
      quais permanecerão plenamente válidas e vinculantes, gerando efeitos em
      sua máxima extensão.<br />
      9.3. As Partes elegem o foro da comarca de São Paulo, SP, como competente
      para dirimir quaisquer disputas decorrentes do presente Contrato,
      renunciando a qualquer outro, por mais privilegiado que seja. As Partes
      declaram ter lido e compreendido este Contrato e concordam em cumprir
      todos os termos e condições estabelecidos.<br />
      E por estarem justas e contratadas, firmam o presente Contrato em 2 (duas)
      vias de igual teor e forma, na presença de 2 (duas) testemunhas.
    </p>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {},
};
</script>

<style scoped>
.negrito {
  font-weight: bolder;
}
.sublinhado {
  text-decoration: underline;
}
</style>
