<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Procedimento Valor</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="procedimentoValorList"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat class="py-4 mb-5">
          <!-- DIALOG DE CADASTRO -->
          <v-dialog persistent v-model="dialogCadastrar" max-width="520px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                dark
                class="mb-2 bg-verde"
                rounded
                title="Exportar"
                v-bind="attrs"
                v-on="on"
              >
                Novo Valor
              </v-btn>
            </template>
            <modal-cadastro
              @response="atualizarProcedimentoValorPosCadastro"
              @close="closeDialogCadastrar"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title
                class="headline d-flex justify-center p-3"
                style="word-break: normal"
              >
                <span v-if="deleteProcedimentoOutraUnidade">
                  Esse valor pertence a outra unidade. Tem certeza que deseja
                  excluí-lo?
                </span>
                <span v-else>
                  Tem certeza de que deseja excluir este valor?
                </span>
              </v-card-title>
              <v-card-actions class="p-3">
                <v-spacer></v-spacer>
                <v-btn color="#1daf80" small text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :loading="loadingDelete"
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  small
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <div class="d-flex mt-5 mr-5 gap-1">
            <v-select
              :items="status"
              item-value="id"
              item-text="label"
              class="mr-5 col-3"
              v-model="filtroStatus"
              v-if="allowCadastrarBtn"
            ></v-select>
            <v-select
              :items="unidades"
              label="Unidade"
              item-value="id"
              class="mr-5"
              v-model="unidadeSelected"
              v-if="allowCadastrarBtn"
            ></v-select>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              placeholder="Pesquisar"
              single-line
              hide-details
            />

            <v-speed-dial
              v-if="allowCadastrarBtn"
              v-model="fab"
              :direction="direction"
              :open-on-hover="hover"
              :transition="transition"
              class="ml-5"
            >
              <template v-slot:activator>
                <v-btn text small class="mt-1">
                  <!-- <v-icon v-if="fab">
                    mdi-close
                  </v-icon>
                  <span v-else>
                    Exportar
                  </span> -->
                  <v-icon>mdi-printer-outline</v-icon>
                </v-btn>
              </template>
              <div class="mt-n7">
                <v-btn dark color="green" class="btnWid" @click="pdfRelatorio">
                  PDF
                </v-btn>
                <v-btn
                  dark
                  color="indigo"
                  class="btnWid"
                  @click="excelRelatorio"
                >
                  EXCEL
                </v-btn>
              </div>
            </v-speed-dial>
          </div>

          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            persistent
            max-width="500px"
            v-if="dialogEdicao"
            v-model="dialogEdicao"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :procedimentoValor="editedProcedimentoValor"
              @close="closeDialogEdited"
              @response="atualizarProcedimentoValorPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowEditarBtn & (item.status == 1)"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="allowEditarBtn & item.status"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
        <v-btn
          v-if="allowEditarBtn & (item.status == 0)"
          small
          text
          color="#1daf80"
          @click="ativarProcedimentoValor(item)"
          >Ativar</v-btn
        >
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import modalCadastro from "@/components/setor/modal_cadastro";
import UnidadeService from "../services/unidade_service";
import modalEdicao from "@/components/procedimento_valor/modal_edicao";
import modalCadastro from "@/components/procedimento_valor/modal_cadastro";
import ProcedimentoValorService from "../services/procedimento_valor_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import endpoints from "@/router/endpoints";
import AuthService from "@/services/auth_service";

export default {
  mixins: [Mixin],
  components: {
    modalCadastro,
    modalEdicao,
  },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    deleteProcedimentoOutraUnidade: false,
    dialog: false,
    dialogObs: false,
    editedProcedimentoValor: {},
    idToDeleted: 0,
    search: "",
    totalList: 10,
    direction: "bottom",
    fab: false,
    hover: false,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    unidades: [],
    unidadeSelected: 0,
    dialogCadastro: false,
    procedimentoValorList: [],
    headers: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "descricaoProcedimento",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "descricaoConvenio",
      },
      {
        text: "Unidade",
        align: "start",
        sortable: true,
        value: "descricaoUnidade",
      },
      {
        text: "Data de Inicio",
        align: "start",
        sortable: true,
        value: "dataInicio",
      },
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "isValido",
      },
      {
        text: "Valor Cliente",
        align: "start",
        sortable: true,
        value: "valorFormatado",
      },
      {
        text: "Valor Interno",
        align: "start",
        sortable: true,
        value: "valorInternoFormatado",
      },
      {
        text: "Repasse Médico",
        align: "start",
        sortable: true,
        value: "valorRepasseFormatado",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    loadingDelete: false,
    services: {
      procedimentoValor: ProcedimentoValorService.build(),
      authService: AuthService.build(),
      unidadeService: UnidadeService.build(),
    },
    unidadeId: "",
    unidadeProcedimentoValor: "",
    status: [
      { id: 1, label: "Ativos" },
      { id: 0, label: "Inativos" },
      { id: 2, label: "Todos" },
    ],
    filtroStatus: 1,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search() {
      if (this.search.length > 2) {
        this.atualizarProcedimentoValorList();
      }
    },
    unidadeSelected() {
      this.atualizarProcedimentoValorList();
    },
    filtroStatus() {
      this.atualizarProcedimentoValorList();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarProcedimentoValorList();
      },
      deep: true,
    },
  },
  async mounted() {
    this.unidadeId = await JSON.parse(sessionStorage.getItem("vuex")).unidade
      .id;
    this.unidadeSelected = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade.id;
    this.setUnidades();
    this.atualizarProcedimentoValorList();
  },
  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(
        endpoints[
          "ProcedimentoValorController::POST->registerProcedimentoValor"
        ]
      );
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(
        endpoints[
          "ProcedimentoValorController::POST->updateProcedimentoValor/$1"
        ]
      );
    },
    allowDeletarBtn() {
      return this.services.authService.temPermissao(
        endpoints["SetorController::POST->deleteSetor/$1"]
      );
    },
  },
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log(response);
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        });
    },
    atualizarProcedimentoValorPosCadastro() {
      this.$_ACTIONS_showSnackbarMessage({
        message: "O Valor foi cadastrado com sucesso.",
        color: "sucess",
      });
      this.atualizarProcedimentoValorList();
      this.closeDialogCadastrar();
    },
    atualizarProcedimentoValorPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarProcedimentoValorList();
    },
    async atualizarProcedimentoValorList() {
      this.loading = true;
      await this.services.procedimentoValor
        .getProcedimentoValorPaginatedList({
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          sort: this.options.order,
          desc: this.options.desc,
          search: this.search,
          unidade_id: this.unidadeSelected,
          status: this.filtroStatus,
        })
        .then(async (response) => {
          if (response.status === 206) {
            this.procedimentoValorList = [];
            this.loading = false;
          }
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, procedimentos } = data;
          this.totalList = pagination.num_rows;
          this.procedimentoValorList = procedimentos
            ? procedimentos.map(
                ({
                  id,
                  id_convenio_procedimento,
                  valor,
                  valor_interno,
                  valor_repasse_medico,
                  descricao_procedimento,
                  descricao_convenio,
                  id_convenio,
                  id_unidade,
                  descricao_unidade,
                  data_inicio,
                  status,
                }) => {
                  return {
                    id,
                    idConvenioProcedimento: id_convenio_procedimento,
                    valorFormatado: parseFloat(valor).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }),
                    valorInternoFormatado: parseFloat(
                      valor_interno
                    ).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }),
                    valorRepasseFormatado: parseFloat(
                      valor_interno
                    ).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }),
                    valor,
                    valor_interno,
                    valor_repasse_medico,
                    descricaoProcedimento: descricao_procedimento,
                    descricaoConvenio: descricao_convenio,
                    idConvenio: id_convenio,
                    idUnidade: id_unidade,
                    descricaoUnidade: descricao_unidade,
                    dataInicio: data_inicio,
                    isValido: status === "1" ? "ATIVO" : "INATIVO",
                    status,
                  };
                }
              )
            : {};
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(item) {
      this.editedProcedimentoValor = item;
      this.dialogEdicao = true;
    },

    deleteItem(item) {
      // console.log(this.unidadeId);
      this.unidadeProcedimentoValor = parseInt(item.idUnidade);
      this.idToDeleted = item.id;

      if (this.unidadeProcedimentoValor != this.unidadeId) {
        this.deleteProcedimentoOutraUnidade = true;
      } else {
        this.deleteProcedimentoOutraUnidade = false;
      }

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      this.services.procedimentoValor
        .deletar(this.idToDeleted, this.unidadeProcedimentoValor)
        .then(async ({ status }) => {
          this.$_ACTIONS_showSnackbarMessage({
            message:
              status === 200
                ? "O Valor foi deletado com sucesso."
                : this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
          status === 200 && (await this.atualizarProcedimentoValorList());
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingDelete = false;
          this.closeDelete();
        });
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    pdfRelatorio() {
      this.loadingPdf = true;
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const search = this.search ? this.search : "";

      this.services.procedimentoValor
        .pdfProcedimentoValorRelatorio(unidade, search)
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `relatorio-procedimento-valor.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },

    excelRelatorio() {
      this.loadingPdf = true;
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const search = this.search ? this.search : "";

      this.services.procedimentoValor
        .excelProcedimentoValorRelatorio(unidade, search)
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `relatorio-procedimento-valor.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },

    ativarProcedimentoValor(procedimento) {
      const responseFunction = {
        onSucess: (status) => (resp) => {
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "O procedimento-valor foi ativado com sucesso",
              color: "sucess",
            });
            this.atualizarProcedimentoValorList();
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {},
      };
      this.services.procedimentoValor.ativar(procedimento.id, responseFunction);
    },
  },
};
</script>
