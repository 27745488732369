<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Exames do meu Pet</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div class="container mt-2">
        <div class="row d-none d-sm-block">
          <div class="col-12">
            <v-card elevation="1">
              <v-card-title>
                Novo Exame
              </v-card-title>
              <v-card-subtitle>
                <div class="row justify-content-between px-4">
                  <v-text-field
                    label="Descrição do arquivo"
                    class="col-4"
                    v-model="obs"
                  ></v-text-field>
                  <v-file-input
                    class="col-4"
                    accept=".pdf,.doc,.docx"
                    label="Adicionar"
                    v-model="campoArquivo"
                    @change="selectFile"
                  ></v-file-input>
                  <div class="col-3">
                    <v-btn
                      :loading="uploading"
                      :disabled="!formValido"
                      :dark="formValido"
                      color="#1daf80"
                      @click="addDocumento"
                    >
                      Salvar arquivo
                      <v-icon right dark small>
                        fas fa-upload
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-subtitle>
            </v-card>
          </div>
        </div>
        <v-template class="d-block d-sm-none">
          <div class="row justify-content-center">
            <!-- <div class="col-12">
              <h3>
                Novo Exame
              </h3>
            </div> -->
            <div class="col-11">
              <div class="form-group mob-style">
                <label for="nome">Descrição Arquivo</label>
                <v-text-field v-model="obs"></v-text-field>
              </div>
              <div class="form-group mob-style">
                <label for="nome">Adicionar</label>
                <v-file-input
                  accept=".pdf,.doc,.docx"
                  v-model="campoArquivo"
                  @change="selectFile"
                ></v-file-input>
              </div>
              <v-btn
                :loading="uploading"
                :disabled="!formValido"
                :dark="formValido"
                class="btn-mob-upload"
                color="#1daf80"
                @click="addDocumento"
              >
                Salvar arquivo
              </v-btn>
            </div>
          </div>
        </v-template>
      </div>
      <v-container>
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :search="search"
          :headers="headers"
          :items="listArquivos"
          :options.sync="options"
          :server-items-length="totalList"
          :loading="loading"
          sort-by="descricao"
          class="elevation-1"
          mobile-breakpoint="350"
        >
          <template v-slot:top class="my-4">
            <v-toolbar flat>
              <div class="row justify-content-end mt-2">
                <div class="col-12 col-sm-4 mb-3">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Pesquisar"
                    single-line
                    hide-details
                  />
                </div>
              </div>
              <!-- DIALOG DE EXCLUSÃO-->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline" style="word-break: normal"
                    >Tem certeza de que deseja excluir este documento?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#1daf80" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      class="btn-danger"
                      text
                      style="color: #fff !important"
                      @click="deleteItemConfirm"
                      >Deletar</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.descricao="{ item }">
            <span :title="item.descricao">
              {{ limitarText(item.descricao) }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              small
              @click="donwloadItem(item)"
              title="Baixar arquivo"
            >
              fas fa-download
            </v-icon>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
            <v-btn color="#1daf80" dark @click="atualizarArquivos">
              Atualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DocumentoService from "@/services/documentos_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],

  props: {
    arquivos: Array,
    paciente: Object,
  },
  mounted() {
    this.atualizarArquivos();
  },
  data: () => ({
    uploading: false,
    obs: "",
    tutor_view: true,
    file: "",
    campoArquivo: "",
    dialogDelete: false,
    formValido: false,
    listArquivos: [],
    editedfile: "",
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    tutor_view_checkbox: JSON.parse(sessionStorage.vuex).perfil.id === 4,
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: false,
        value: "descricao",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
  }),

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        // this.atualizarArquivos();
      },
      deep: true,
    },
    search() {
      // this.atualizarArquivos();
    },
    paciente() {
      // this.atualizarArquivos();
    },
    obs() {
      this.validarForm();
    },
    file() {
      this.validarForm();
    },
    campoArquivo() {
      this.validarForm();
    },
  },

  methods: {
    validarForm() {
      // console.log("this.file");
      // console.log(this.file);
      // console.log("this.campoArquivo");
      // console.log(this.campoArquivo);
      // console.log("this.obs");
      // console.log(this.obs);
      if (
        this.campoArquivo != "" &&
        this.campoArquivo != null &&
        this.obs.trim().length > 3
      ) {
        this.formValido = true;
      } else {
        this.formValido = false;
      }
    },
    selectFile() {
      this.file = document.querySelector("input[type=file]").files[0];
    },
    atualizarArquivos() {
      const documentoService = new DocumentoService();
      this.loading = true;
      this.listArquivos = [];
      documentoService.buscar_arquivos_exames(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.totalList = body.data.pagination.num_rows;
              this.listArquivos = body.data.arquivo;
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.paciente.id_paciente,
        this.paciente.id_pet,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },

    addDocumento() {
      const documentoService = new DocumentoService();
      this.dialogLoading = true;
      var formdata = new FormData();
      const descricao = this.obs;

      formdata.append("arquivo", this.campoArquivo);
      formdata.append("id_paciente", this.paciente.id_paciente);
      formdata.append("id_pet", this.paciente.id_pet);
      formdata.append("id_clinica", null);
      formdata.append("descricao", descricao);
      formdata.append("id_medico", null);
      formdata.append("tutor_view", this.tutor_view == true ? 1 : 0);

      documentoService.registerExame(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.atualizarArquivos();
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.uploading = false;
            this.campoArquivo = "";
            this.file = null;
            this.obs = "";
          },
        },
        formdata
      );
    },
    deleteItem(item) {
      this.editedfile = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      const documentoService = new DocumentoService();
      this.loading = true;
      documentoService.deletar_arquivo_exame(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.atualizarArquivos();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogDelete = false;
            this.loading = false;
          },
        },
        this.editedfile
      );
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    donwloadItem(item) {
      const documentoService = new DocumentoService();
      this.loading = true;
      documentoService.ver_arquivo_exame(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              var file = document.createElement("a"); //Create <a>
              const listFilesApplication = ["pdf", "doc", "docx"];
              const ext = listFilesApplication.includes(body.data.ext)
                ? "application/"
                : "image/";
              file.href =
                "data:" + ext + body.data.ext + ";base64," + body.data.arquivo;
              file.download = body.data.descricao + "." + body.data.ext;
              file.click();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        item.id
      );
    },
    limitarText(text, caracteres = 15) {
      if (text.length > caracteres) {
        return text.slice(0, caracteres) + "...";
      } else {
        return text;
      }
    },
  },
};
</script>
<style scoped>
.btn-mob-upload {
  border-radius: 20px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mob-style {
  text-align: left;
}
.mob-style label {
  font-weight: bold;
  margin-bottom: 0px;
}
.mob-style input {
  margin-top: 0px;
}
.mob-style .v-input {
  margin-top: 0px !important;
}
</style>
