<template>
  <div>
    <v-form class="p-lg-3 row" ref="form" v-model="valid" lazy-validation>
      <div class="col-lg-12 row">
        <!-- CAMPO DE DATA INÍCIO -->
        <div class="col-lg-3 form-group clearfix mb-3">
          <v-text-field
            label="Data"
            v-model="data"
            :rules="validationData"
            clear-icon="clear"
            required
            style="margin: 0 5px; padding-top: 0px !important"
            :type="'date'"
            :outlined="false"
          />
        </div>
        <!-- CAMPO DE HORA INÍCIO -->
        <div class="col-lg-3 form-group clearfix mb-3">
          <v-text-field
            label="Hora Início"
            v-model="horaInicio"
            :rules="validationHorarioInicio"
            :type="'text'"
            required
            v-mask="'##:##'"
            placeholder="Hora Início"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <!-- CAMPO DE HORA FINAL -->
        <div class="col-lg-3 form-group clearfix mb-s3">
          <v-text-field
            label="Hora Final"
            :error-messages="erroHorarioMenorQue"
            v-model="horaFinal"
            :rules="validationHorarioFinal"
            :type="'text'"
            v-mask="'##:##'"
            required
            placeholder="Hora Final"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
      </div>
      <div class="col-lg-12 row">
        <!-- CAMPO DE PROCEDIMENTO -->
        <div class="col-lg-4 form-group clearfix mb-3 text-dark">
          <label>Procedimento</label>
          <v-select
            v-model="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento"
            :items="TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento"
            :loading="TIPO_PROCEDIMENTO_MIXIN_loading"
            :rules="validationTipoProcedimento"
            required
            label="Tipo de Procedimento"
            item-text="descricao"
            item-value="id"
            color="black"
            solo
          >
          </v-select>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3 text-dark">
          <label>Médico</label>
          <v-select
            v-model="medicoId"
            :rules="validationMedico"
            :items="medicos"
            :loading="loadingListarMedicos"
            required
            label="Médico"
            item-text="nome"
            item-value="medicoId"
            color="black"
            solo
            >/
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-html="item.nome"></v-list-item-title>
                <v-list-item-subtitle
                  class="mt-1"
                  v-html="item.email"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </div>
      </div>
      <!-- BOTÃO SUBMIT FORMULÁRIO -->
      <div>
        <v-btn class="btn-app-primary" @click="criarAgenda" :loading="loading">
          <span>Criar Agenda</span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import AgendaService from "@/services/agenda_service.js";
import ValidationService from "@/services/validation_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import MedicoService from "../../services/medico_service";

export default {
  mixins: [
    VuexSnackbarMixin,
    VuexUsuarioMixin,
    TipoProcedimentoMixin,
    DadosCadastraisMixin,
  ],
  beforeMount() {
    this.validationService = ValidationService;
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista();
  },
  data: () => ({
    loading: false, // Guarda o estado do loading de submit
    loadingListarMedicos: false,
    valid: false, // Guarda a variável que guarda o estado da validação do formulário
    data: "",
    horaInicio: "",
    horaFinal: "",
    validationService: {},
    medicoService: MedicoService.build(),
    medicos: [],
    medicoId: 0,
  }),
  async mounted() {
    this.loadingListarMedicos = true;
    this.medicos = await this.medicoService.getMedicoListByUnidadeId(
      this.unidadeId
    );
    this.loadingListarMedicos = false;
  },
  computed: {
    unidadeId() {
      return this.$_GETTER_Unidade.id;
    },
    erroHorarioMenorQue() {
      const tempoMinimoConsulta = this.$global.consultas.tempoMinimo;
      const error = this.validationService.horarioMaiorQue(
        this.horaInicio,
        this.horaFinal,
        tempoMinimoConsulta / 60,
        `A consulta deve ter no mínimo ${tempoMinimoConsulta} minutos`
      )();
      return error === true ? "" : error;
    },
    validationData() {
      return [this.validationService.required("Informe uma data.")];
    },
    validationHorarioInicio() {
      return [
        this.validationService.required("Informe uma hora de início."),
        this.validationService.isHorario24Horas(),
        this.validationService.horarioMaiorQue(this.horaInicio, this.horaFinal),
      ];
    },
    validationHorarioFinal() {
      return [
        this.validationService.required("Informe uma hora final."),
        this.validationService.isHorario24Horas(),
        this.validationService.horarioMaiorQue(
          this.horaInicio,
          this.horaFinal,
          60 * this.$global.consultas.tempoMinimo,
          `A consulta deve ter, no mínimo, ${this.$global.consultas.tempoMinimo} minutos.`,
          `A consulta deve ter, no mínimo, ${this.$global.consultas.tempoMinimo} minutos.`
        ),
      ];
    },
    validationTipoProcedimento() {
      return [
        this.validationService.required("Informe um tipo de procedimento."),
      ];
    },
    validationMedico() {
      return [this.validationService.required("Informe um médico.")];
    },
  },
  methods: {
    criarAgenda() {
      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      this.loading = true;
      new AgendaService().criarAgenda(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            status === 200 && this.$emit("response");
            this.$refs.form.reset();
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        {
          id_medico: this.medicoId,
          data: this.data,
          hora_inicio: this.horaInicio,
          hora_final: this.horaFinal,
          id_tipo_procedimento: this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento,
          adicionado_por: this.$_GETTERS_usuario.id,
          id_unidade: this.unidadeId,
        }
      );
    },
  },
};
</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(19, 125, 91) !important;
  border-color: rgb(19, 125, 91);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>
