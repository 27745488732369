<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Encerrar Agendamento </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>

    <v-form ref="form" class="container mt-6" v-model="form" lazy-validation>
      <div class="row justify-content-center align-items-center">
        <div class="col-10 col-sm-12 text-center">
          <strong
            >Você está prestes a encerrar o agendamento sem finalizá-lo. Deseja
            realmente encerrar?</strong
          >
        </div>
      </div>
      <div class="row justify-content-around align-items-center mb-5">
        <div class="col-10 col-sm-6 text-center">
          <button
            @click.prevent="encerrar"
            class="btn btn-app-primary btn-color-enc font-weight-bold"
          >
            <span v-if="!loading">Confirmar Encerramento</span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
        <div class="col-10 col-sm-6 text-center">
          <button
            @click="$emit('close')"
            class="btn btn-app-primary btn-color-cancelar font-weight-bold"
          >
            Continuar Agendamento
          </button>
        </div>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import AgendamentoService from "@/services/agendamento_service.js";

export default {
  mixins: [Mixin],
  props: {
    infos: {
      type: Object,
      // required: true,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      services: {
        agendamentoService: AgendamentoService.build(),
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    async encerrar() {
      this.loading = true;
      await this.services.agendamentoService
        .encerrarAgendarTeleConsulta(this.infos.id_agendamento)
        .then(async (response) => {
          if (response.status !== 200) {
            this.loading = false;
            return;
          }
          this.$emit("confirmado");
        });
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
.layout-style {
  display: flex;
  justify-content: end;
}
.btn-app-primary.btn-color-enc {
  background-color: #737373 !important;
}
.btn-app-primary.btn-color-cancelar {
  background-color: #0c7b57 !important;
}
@media (max-width: 575.98px) {
  .btn-app-primary {
    width: 100%;
  }
  .layout-style {
    justify-content: center;
  }
}
</style>
