<template>
  <div id="app">
    <v-app>
      <div v-if="estaLogado">
        <v-navigation-drawer
          class="mob-style"
          v-model="drawer"
          app
          mobile-breakpoint="600"
        >
          <menu-principal :isClient="isClient" />
        </v-navigation-drawer>
        <v-system-bar>
          <header-pagina @retratil="drawer = !drawer" />
        </v-system-bar>
        <v-main>
          <div class="page-content">
            <div div class="card">
              <div class="card-body">
                <router-view />
              </div>
            </div>
          </div>
        </v-main>
      </div>
      <div v-else>
        <!-- <home v-if="$_GETTER_perfil.description === 'home'" @login="setPerfil($event)"></home> -->
        <!-- <LoginHome v-if="$_GETTER_perfil.description === 'home'"></LoginHome> -->
        <div>
          <router-view />
        </div>
      </div>
      <snackbar />
    </v-app>
  </div>
</template>

<script>
// import MenuGeral from "@/components/templates/MenuGeral.vue";
import MenuPrincipal from "@/components/templates/MenuLateral.vue";
import HeaderPagina from "@/components/templates/Header.vue";

// import Home from "@/views/home";
// import LoginHome from "@/components/login/login.vue";
import Snackbar from "@/components/widgets/snackbar.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: { MenuPrincipal, HeaderPagina, Snackbar },
  mixins: [Mixin],
  mounted() {
    // if (!this.estaLogado && this.$router.history.current.path !== "/home") {
    if (!this.estaLogado) {
      this.$router.replace("login");
    }
  },
  data: () => ({
    drawer: null,
    isClient: false,
  }),
  computed: {
    estaLogado() {
      return this.$_GETTERS_token.length > 0;
    },
    perfilAtual() {
      return this.$_GETTER_perfil.description;
    },
  },
  methods: {
    setPerfil(obj) {
      this.$_ACTIONS_perfil(obj);
    },
  },
};
</script>

<style>
/* App css */
@import "assets/css/bootstrap.min.css";
@import "assets/css/icons.min.css";
@import "assets/css/app.min.css";

/*Plugins css */
@import "assets/libs/bootstrap-select/bootstrap-select.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg-verde {
  background-color: #1daf80 !important;
}

.bg-cinza {
  background-color: rgb(239, 239, 239);
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.page-content {
  /* margin-left: 240px; */
  padding: 50px 15px 0 15px;
  /* margin-top: 120px; */
}

.card {
  border: none;
  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  margin-bottom: 30px;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #1daf80;
  border: 0px none #1daf80;
}
/* ::-webkit-scrollbar-thumb:hover {
  background: #21c994;
} */
/* ::-webkit-scrollbar-thumb:active {
  background: #21c994;
} */
::-webkit-scrollbar-track {
  background: #78ecc89a;
  border: 10px none #ffffff;
}
::-webkit-scrollbar-track:hover {
  background: #78ecc89a;
}
::-webkit-scrollbar-track:active {
  background: #78ecc89a;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.mob-style {
  background-color: #1daf80 !important;
}
@media (max-width: 575.98px) {
  .mob-style {
    z-index: 101 !important;
    background-color: white !important;
  }
}
</style>
