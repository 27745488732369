<template>
  <div>
    <template>
      <!-- <v-hover v-slot="{ hover }"> -->
      <template>
        <v-card :elevation="0">
          <div class="mb-2">
            <v-img
              @click="triggerAddFoto"
              lazy-src="https://picsum.photos/id/11/10/6"
              height="250"
              width="250"
              class="
                mx-auto
                rounded-circle
                d-flex
                justify-content-center
                align-items-center
              "
              :src="preview"
            ></v-img>
          </div>

          <!-- <v-expand-transition>
                                <div
                                    v-if="hover"
                                    class="d-flex transition-fast-in-fast-out"
                                    style="height: 100%; background-color: #1daf80a3;"
                                >
                                    <v-dialog
                                        v-model="dialog"
                                        width="500"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="green darken-4"
                                                v-bind="attrs"
                                                v-on="on"
                                                style="color: #fff !important; position: absolute; top: 50%; left: 50%; transform: translateX(-50%);"
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                                <span>Alterar foto de perfil</span>
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title 
                                                class="headline green darken-4" 
                                                style="color: #fff !important;"
                                            >
                                                Escolha a foto
                                            </v-card-title>

                                            <v-card-text>
                                                <template>
                                                    <v-file-input
                                                        accept="image/*"
                                                        label="File input"
                                                    ></v-file-input>
                                                </template>
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                            <v-spacer></v-spacer>
                                                <v-btn
                                                    color="green darken-4" 
                                                    style="color: #fff !important;"
                                                    @click="dialog = false"
                                                >
                                                    Salvar
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </div>
                            </v-expand-transition> -->
        </v-card>
        <v-file-input
          id="fileInput"
          v-model="foto"
          v-show="false"
          name="foto"
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Adicione uma foto"
          prepend-icon="mdi-camera"
          @change="onChange($event)"
        ></v-file-input>
      </template>
      <!-- </v-hover> -->
    </template>
  </div>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import PictureInput from "vue-picture-input";
export default {
  components: [PictureInput],
  mixins: [DadosCadastraisMixin],

  data: () => ({
    //Chamada do dialog de upload da imagem
    dialog: false,
    addFoto: true,
    preview: "",
    foto: null,
  }),

  beforeMount() {
    this.preview = this.DADOS_CADASTRAIS_MIXIN_srcFotoUsuario;
  },

  methods: {
    trocarImagem() {
      if (this.$refs.pictureInput) {
        this.avatarPaciente = this.$refs.pictureInput.file;
        this.image = this.$refs.pictureInput.image;
      } else {
        console.log("API File Reader não suportada: use o <form>");
      }
    },
    clean() {
      this.addFoto = true;
    },
    triggerAddFoto() {
      document.querySelector("#fileInput").click();
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
