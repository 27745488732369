import HTTPService from "@/services/http_service.js";

export default class DashboardService extends HTTPService {
  resource = "dashboard";
  getDashboarMedico({ onSucess, onError, onEnd }, idMedico, data = null) {
    let url = `${this.resource}/medico/${idMedico}`;
    if (data) {
      url += `?data=${data}`;
    }
    this.request({ onSucess, onError, onEnd }, "GET", url);
  }

  getDashboarMedicoTelevet(
    { onSucess, onError, onEnd },
    idMedico,
    data = null
  ) {
    let url = `${this.resource}/medico-televet/${idMedico}`;
    if (data) {
      url += `?data=${data}`;
    }
    this.request({ onSucess, onError, onEnd }, "GET", url);
  }

  getDashboardPaciente({ onSucess, onError, onEnd }, idPaciente) {
    const url = `${this.resource}/paciente/${idPaciente}`;
    this.request({ onSucess, onError, onEnd }, "GET", url);
  }
}
