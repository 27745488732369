<template>
  <div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <v-card>
          <div class="headerStyle py-2 px-5 mb-4">
            <p class="text-center fontStyle">Pet</p>
          </div>
          <div class="d-flex justify-content-between px-5 pb-3">
            <div><strong>Nome:</strong> {{ pet.nome }}</div>
            <div><strong>Nascimento:</strong> {{ pet.datadenascimento }}</div>
            <div><strong>Cadastro:</strong> {{ pet.datadecadastro }}</div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <v-card>
          <div class="headerStyle py-2 px-5 mb-4">
            <p class="text-center fontStyle">Anamnese</p>
          </div>
          <div class="d-flex justify-content-between px-5 pb-3">
            <div><strong>Data:</strong> {{ anamnese.data }}</div>
            <div><strong>Comanda Id:</strong> {{ anamnese.comanda_id }}</div>
            <div>
              <strong>Responsavel Id:</strong> {{ anamnese.responsavel_id }}
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div class="row mt-8 justify-center">
      <div class="col-10">
        <h3 class="text-center">Anamnese detalhes</h3>
        <div class="styleDetalhes" v-if="anamneseDetail.id != 0">
          <span><strong>Id: </strong>{{ anamneseDetail.id }}</span>
          <span
            ><strong>Comportamento: </strong
            >{{ anamneseDetail.comportamento }}</span
          >
          <span><strong>Data: </strong>{{ anamneseDetail.data }}</span>
          <span
            ><strong>Data para Retorno: </strong
            >{{ anamneseDetail.datapararetorno }}</span
          >
          <span
            ><strong>Encaminhamento: </strong
            >{{ anamneseDetail.encaminhamento }}</span
          >
          <span
            ><strong>Estado Corporal: </strong
            >{{ anamneseDetail.estadocorporal }}</span
          >
          <span
            ><strong>Evolucao do Quadrao: </strong
            >{{ anamneseDetail.evolucaodoquadrao }}</span
          >
          <span><strong>Mucosas: </strong>{{ anamneseDetail.mucosas }}</span>
          <span
            ><strong>Nivel Consciencia: </strong
            >{{ anamneseDetail.nivelconsciencia }}</span
          >
          <span><strong>Peso: </strong>{{ anamneseDetail.peso }}</span>
          <span
            ><strong>Pulso Arterial: </strong
            >{{ anamneseDetail.pulsoarterial }}</span
          >
          <span><strong>Tipo: </strong>{{ anamneseDetail.tipo }}</span>
          <span
            ><strong>Tratamento Prescrito: </strong
            >{{ anamneseDetail.tratamentoprescrito }}</span
          >
          <span
            ><strong>Animal Id: </strong>{{ anamneseDetail.animal_id }}</span
          >
          <span
            ><strong>Comanda Id: </strong>{{ anamneseDetail.comanda_id }}</span
          >
          <span
            ><strong>Profissional Id: </strong
            >{{ anamneseDetail.profissional_id }}</span
          >
          <span
            ><strong>Responsavel Id: </strong
            >{{ anamneseDetail.responsavel_id }}</span
          >
          <span
            ><strong>Data Exclusao: </strong
            >{{ anamneseDetail.data_exclusao }}</span
          >
          <span
            ><strong>Data Cadastro: </strong
            >{{ anamneseDetail.data_cadastro }}</span
          >
          <span
            ><strong>Complemento Id: </strong
            >{{ anamneseDetail.id_complemento }}</span
          >
          <span
            ><strong>Anamnese Id: </strong
            >{{ anamneseDetail.id_anamnese }}</span
          >
          <span
            ><strong>Queixa Principal: </strong
            >{{ anamneseDetail.queixaprincipal }}</span
          >
          <span
            ><strong>Descricao: </strong>{{ anamneseDetail.descricao }}</span
          >
          <span
            ><strong>Demais Achados: </strong
            >{{ anamneseDetail.demaisachados }}</span
          >
          <span><strong>Dieta: </strong>{{ anamneseDetail.dieta }}</span>
          <span
            ><strong>Hidratacao: </strong>{{ anamneseDetail.hidratacao }}</span
          >
          <span
            ><strong>Descrição Anestesica: </strong
            >{{ anamneseDetail.descricaoanestesica }}</span
          >
          <span
            ><strong>Descricao Cirurgica: </strong
            >{{ anamneseDetail.descricaocirurgica }}</span
          >
          <span
            ><strong>Observação Proprietario: </strong
            >{{ anamneseDetail.observacaoproprietario }}</span
          >
          <span
            ><strong>Observacoes: </strong
            >{{ anamneseDetail.observacoes }}</span
          >
          <span
            ><strong>Detalhe Id: </strong>{{ anamneseDetail.id_detalhe }}</span
          >
          <span><strong>Anamnese: </strong>{{ anamneseDetail.anamnese }}</span>
          <span
            ><strong>Diagnostico: </strong
            >{{ anamneseDetail.diagnostico }}</span
          >
          <span
            ><strong>Suspeita Diagnostica: </strong
            >{{ anamneseDetail.suspeitadiagnostica }}</span
          >
          <span
            ><strong>Tratamento: </strong>{{ anamneseDetail.tratamento }}</span
          >
          <span
            ><strong>Anamneses Implificada: </strong
            >{{ anamneseDetail.anamnesesimplificada }}</span
          >
          <span
            ><strong>Exames Id: </strong>{{ anamneseDetail.id_exames }}</span
          >
          <span
            ><strong>Exame Fisico fc: </strong
            >{{ anamneseDetail.examefisicofc }}</span
          >
          <span
            ><strong>Exame Fisico fr: </strong
            >{{ anamneseDetail.examefisicofr }}</span
          >
          <span
            ><strong>Exame Fisico lfn: </strong
            >{{ anamneseDetail.examefisicolfn }}</span
          >
          <span
            ><strong>Exame Fisico tpc: </strong
            >{{ anamneseDetail.examefisicotpc }}</span
          >
          <span
            ><strong>Exame Fisico tr: </strong
            >{{ anamneseDetail.examefisicotr }}</span
          >
          <span
            ><strong>Exames Complementares: </strong
            >{{ anamneseDetail.examescomplementares }}</span
          >
        </div>
        <div class="styleDetalhes" v-else>
          <h4 class="text-center">Anamnese sem detalhes.</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import LegacyVetusService from "../../services/legacy_vetus";

export default {
  mixins: [DadosCadastraisMixin],
  props: {
    pet: Object,
    anamnese: Object,
    anamneseDetail: Object,
  },
  data() {
    return {
      services: {
        legacyVetusService: LegacyVetusService.build(),
      },
      loading: true,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "descricao",
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      // Listas
      // anamneseDetail: {
      //   id: 0,
      //   comportamento: 0,
      //   data: "",
      //   datapararetorno: "",
      //   encaminhamento: "",
      //   estadocorporal: 0,
      //   evolucaodoquadrao: 0,
      //   mucosas: 0,
      //   nivelconsciencia: 0,
      //   peso: 0,
      //   pulsoarterial: 0,
      //   tipo: 0,
      //   tratamentoprescrito: 0,
      //   animal_id: 0,
      //   comanda_id: 0,
      //   profissional_id: 0,
      //   responsavel_id: 0,
      //   data_exclusao: "",
      //   data_cadastro: "",
      //   id_complemento: 0,
      //   id_anamnese: 0,
      //   queixaprincipal: "",
      //   descricao: "",
      //   demaisachados: "",
      //   dieta: "",
      //   hidratacao: "",
      //   descricaoanestesica: "",
      //   descricaocirurgica: "",
      //   observacaoproprietario: "",
      //   observacoes: "",
      //   id_detalhe: 0,
      //   anamnese: "",
      //   diagnostico: "",
      //   suspeitadiagnostica: "",
      //   tratamento: "",
      //   anamnesesimplificada: "",
      //   id_exames: 0,
      //   examefisicofc: 0,
      //   examefisicofr: 0,
      //   examefisicolfn: "",
      //   examefisicotpc: 0,
      //   examefisicotr: 0,
      //   examescomplementares: "",
      // },
    };
  },
  mounted() {},
  computed: {},
  methods: {
    async getListFile() {
      this.loading = true;
      await this.services.legacyVetusService
        .getAnamneseDetailbyID(this.anamnese.id)
        .then(async (response) => {
          const data = await response.json();
          data[0].data = this.convertDate(data[0].data);
          data[0].datapararetorno = this.convertDate(data[0].datapararetorno);
          data[0].data_exclusao = this.convertDate(data[0].data_exclusao);
          data[0].data_cadastro = this.convertDate(data[0].data_cadastro);

          this.anamneseDetail = data[0];
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    convertDate(date) {
      if (date) {
        const data = new Date(date);
        return (
          data
            .getDay()
            .toString()
            .padStart(2, "0") +
          "/" +
          data
            .getMonth()
            .toString()
            .padStart(2, "0") +
          "/" +
          data.getFullYear()
        );
      } else {
        return "--/--/----";
      }
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}

.headerStyle {
  background-color: #e0e0e0;
}

.fontStyle {
  font-weight: bolder;
  margin-bottom: 0px;
  font-size: 1.1rem;
}
.styleDetalhes {
  display: flex;
  flex-direction: column;
  gap: 2;
}
.styleDetalhes span {
  text-align: left;
  margin-bottom: 19px;
  box-shadow: 0px 3px 4px -1px rgba(0, 0, 0, 0.1);
}
</style>
