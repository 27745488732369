import HTTPService from "@/services/http_service.js";

export default class K2Service extends HTTPService {
  static build() {
    return new K2Service();
  }
  /**
        @returns {Promise<Response>}
     */

  // Cadastar número cartão medicina com carinho
  postSendMensagem(model) {
    model.number = "+55" + model.number;
    return this.postK2Web("", model);
  }
}
