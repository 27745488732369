<template>
  <div>
    <v-form ref="form" lazy-validation>
      <div class="row justify-center">
        <div class="col-lg-9 form-group clearfix my-3">
          <label for="CPF">CPF Tutor</label>
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="cpf"
            :rules="DADOS_CADASTRAIS_MIXIN_validationCPF"
            :type="'text'"
            placeholder="000.000.000-00"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-lg-1 form-group clearfix my-3">
          <v-btn
            class="mt-2 align-self-center"
            outlined
            color="#1daf80"
            :disabled="cpf.length < 14"
            @click="pesquisarTutor"
          >
            Pesquisa
          </v-btn>
        </div>
      </div>
      <div v-if="tutor.id != 0" class="row justify-center">
        <div class="col-10">
          <v-card>
            <div class="headerStyle py-2 px-5 mb-4">
              <p class="text-center fontStyle">Tutor</p>
            </div>
            <div class="d-flex justify-content-between px-5 pb-3">
              <div><strong>Nome:</strong> {{ tutor.nome }}</div>
              <div><strong>Cpf:</strong> {{ tutor.cpfcnpj }}</div>
              <div><strong>Cidade:</strong> {{ tutor.cidade }}</div>
            </div>
          </v-card>
        </div>
      </div>
      <div v-if="listPets.length > 0" class="row justify-center">
        <div class="col-10">
          <h5 class="text-left">Lista Pets</h5>
          <v-data-table
            :headers="petHeader"
            :items="listPets"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                title="Anamneses"
                small
                class="mr-2"
                @click="anamnesePet(item)"
              >
                mdi mdi-clipboard-text-outline
              </v-icon>
              <v-icon
                title="Arquivos"
                small
                class="mr-2"
                @click="filePet(item)"
              >
                mdi-download
              </v-icon>

              <v-dialog
                fullscreen
                hide-overlay
                max-width="500px"
                v-if="dialogArquivos"
                v-model="dialogArquivos"
              >
                <v-card v-if="dialogArquivos">
                  <v-toolbar style="background-color: #1daf80; color: #fff">
                    <v-btn icon dark @click="dialogArquivos = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span class="headline">Arquivos</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <modal-file-vetus
                    v-if="dialogArquivos"
                    :petFile="petFile"
                    :listArquivos="listArquivos"
                    @close="dialogArquivos = false"
                  />
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="mt-10" v-if="pet.id != 0">
        <div class="row justify-center">
          <div class="col-10">
            <v-card>
              <div class="headerStyle py-2 px-5 mb-4">
                <p class="text-center fontStyle">Pet</p>
              </div>
              <div class="d-flex justify-content-between px-5 pb-3">
                <div><strong>Nome:</strong> {{ pet.nome }}</div>
                <div>
                  <strong>Nascimento:</strong> {{ pet.datadenascimento }}
                </div>
                <div><strong>Cadastro:</strong> {{ pet.datadecadastro }}</div>
              </div>
            </v-card>
          </div>
        </div>
        <div v-if="!loadTable" class="row justify-center">
          <div class="col-10" v-if="listAnamnese.length > 0">
            <h5 class="text-left">Lista Anamnese</h5>
            <v-data-table
              :headers="anamneseHeader"
              :items="listAnamnese"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  title="Detalhe"
                  small
                  class="mr-2"
                  @click="getAnamneseDetail(item)"
                >
                  mdi mdi-clipboard-text-search-outline
                </v-icon>
                <v-icon
                  :disabled="item.id_anamnese_internacao == 0"
                  title="Internação"
                  small
                  :class="
                    item.id_anamnese_internacao == 0
                      ? 'btn-disable-icon-style'
                      : 'btn-icon-style'
                  "
                  class="mr-2"
                  @click="getAnamneseInternacao(item)"
                >
                  mdi-clipboard-pulse-outline
                </v-icon>

                <v-dialog
                  fullscreen
                  hide-overlay
                  max-width="500px"
                  v-if="dialogAnamneseInternacao"
                  v-model="dialogAnamneseInternacao"
                >
                  <v-card v-if="dialogAnamneseInternacao">
                    <v-toolbar style="background-color: #1daf80; color: #fff">
                      <v-btn
                        icon
                        dark
                        @click="dialogAnamneseInternacao = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>
                        <span class="headline">Anamnese Internação</span>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items> </v-toolbar-items>
                    </v-toolbar>
                    <modal-anamnese-internacao
                      v-if="dialogAnamneseInternacao"
                      :pet="pet"
                      :anamnese="anamnese"
                      :anamneseInternacao="anamneseInternacao"
                      @close="dialogAnamneseInternacao = false"
                    />
                  </v-card>
                </v-dialog>
                <v-dialog
                  fullscreen
                  hide-overlay
                  max-width="500px"
                  v-if="dialogAnamneseDetail"
                  v-model="dialogAnamneseDetail"
                >
                  <v-card v-if="dialogAnamneseDetail">
                    <v-toolbar style="background-color: #1daf80; color: #fff">
                      <v-btn icon dark @click="dialogAnamneseDetail = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>
                        <span class="headline">Anamnese Detalhe</span>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items> </v-toolbar-items>
                    </v-toolbar>
                    <modal-anamnese-detail
                      v-if="dialogAnamneseDetail"
                      :pet="pet"
                      :anamnese="anamnese"
                      :anamneseDetail="anamneseDetail"
                      @close="dialogAnamneseDetail = false"
                    />
                  </v-card>
                </v-dialog>
              </template>
            </v-data-table>
          </div>
          <div class="col-10" v-else>
            <h3 class="text-center">Sem Anamnese para esse Pet!</h3>
          </div>
        </div>
        <div v-else>
          <v-progress-circular
            class="mt-4"
            width="3"
            size="80"
            indeterminate
            color="green"
          ></v-progress-circular>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import LegacyVetusService from "../../services/legacy_vetus";
import modalFileVetus from "@/components/legado_vetus/modal_file_vetus";
import modalAnamneseDetail from "@/components/legado_vetus/modal_anamnese_detail";
import modalAnamneseInternacao from "@/components/legado_vetus/modal_anamnese_internacao";

export default {
  mixins: [DadosCadastraisMixin],
  components: { modalFileVetus, modalAnamneseDetail, modalAnamneseInternacao },
  data() {
    return {
      services: {
        legacyVetusService: LegacyVetusService.build(),
      },
      loadTable: false,
      // Dialog
      dialogArquivos: false,
      dialogAnamneseDetail: false,
      dialogAnamneseInternacao: false,
      // Formulario
      cpf: "",
      // Tutor
      tutor: {
        id: 0,
        nome: "",
        cpfcnpj: "",
        datacadastro: "",
        cidade: "",
      },
      // Pet
      pet: {
        id: 0,
        nome: "",
        datadecadastro: "",
        datadenascimento: "",
      },
      petFile: {
        id: 0,
        nome: "",
        datadecadastro: "",
        datadenascimento: "",
      },
      anamneseInternacao: {},
      anamneseDetail: {},
      // Anamnese
      anamnese: {
        id: 0,
        data: "",
        comanda_id: "",
        professional_id: "",
        responsavel_id: "",
        id_anamnese_internacao: "",
      },
      // Tables
      petHeader: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "nome",
        },
        {
          text: "Nascimento",
          align: "start",
          sortable: false,
          value: "datadenascimento",
        },
        {
          text: "Cadastro",
          align: "start",
          sortable: false,
          value: "datadecadastro",
        },
        {
          text: "Ações",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      anamneseHeader: [
        {
          text: "Id Anamnese",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Data",
          align: "start",
          sortable: false,
          value: "data",
        },
        {
          text: "Id Comanda",
          align: "start",
          sortable: false,
          value: "comanda_id",
        },
        {
          text: "Id Profissional",
          align: "start",
          sortable: false,
          value: "profissional_id",
        },
        {
          text: "Id Responsavel",
          align: "start",
          sortable: false,
          value: "responsavel_id",
        },
        {
          text: "Ações",
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      // Listas
      listPets: [],
      listAnamnese: [],
      listArquivos: [],
    };
  },
  async mounted() {},
  computed: {},
  methods: {
    async pesquisarTutor() {
      this.limparGeral();
      await this.services.legacyVetusService
        .getClientbyCPF(this.cpf)
        .then(async (response) => {
          const data = await response.json();
          this.tutor = {
            id: data[0].id,
            nome: data[0].nome,
            cpfcnpj: data[0].cpfcnpj,
            datacadastro: data[0].datacadastro,
            cidade: data[0].cidade,
          };
          this.getListPet(this.tutor.id);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getListPet(id) {
      this.limparPets();
      await this.services.legacyVetusService
        .getPetbyClientID(id)
        .then(async (response) => {
          const data = await response.json();
          data.map((element) => {
            element.datadecadastro = this.convertDate(element.datadecadastro);
            element.datadenascimento = this.convertDate(
              element.datadenascimento
            );
            this.listPets.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async anamnesePet(item) {
      this.loadTable = true;
      this.limparPet();
      this.pet = {
        id: item.id,
        nome: item.nome,
        datadecadastro: item.datadecadastro,
        datadenascimento: item.datadenascimento,
      };
      this.limparAnamnese();
      await this.services.legacyVetusService
        .getAnamnesebyPetID(item.id)
        // .getAnamnesebyPetID(21893)
        .then(async (response) => {
          const data = await response.json();
          data.map((element) => {
            element.data = this.convertDate(element.data);
            this.listAnamnese.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadTable = false;
        });
    },

    async filePet(item) {
      this.limparPetFile();
      this.petFile = {
        id: item.id,
        nome: item.nome,
        datadecadastro: item.datadecadastro,
        datadenascimento: item.datadenascimento,
      };
      this.listArquivos = [];
      await this.services.legacyVetusService
        // .getFilebyPetID(474217)
        .getFilebyPetID(this.petFile.id)
        .then(async (response) => {
          const data = await response.json();
          this.listArquivos = data;
          this.dialogArquivos = true;
        })
        .catch((error) => {
          this.dialogArquivos = true;
          console.log(error);
        });
    },

    async getAnamneseDetail(item) {
      this.limparAnamneseDetail();
      this.anamnese = {
        id: item.id,
        data: item.data,
        comanda_id: item.comanda_id,
        professional_id: item.professional_id,
        responsavel_id: item.responsavel_id,
        id_anamnese_internacao: item.id_anamnese_internacao,
      };
      this.anamneseDetail = { id: 0 };
      await this.services.legacyVetusService
        .getAnamneseDetailbyID(this.anamnese.id)
        .then(async (response) => {
          const data = await response.json();
          data[0].data = this.convertDate(data[0].data);
          data[0].datapararetorno = this.convertDate(data[0].datapararetorno);
          data[0].data_exclusao = this.convertDate(data[0].data_exclusao);
          data[0].data_cadastro = this.convertDate(data[0].data_cadastro);

          this.anamneseDetail = data[0];
          this.dialogAnamneseDetail = true;
        })
        .catch((error) => {
          console.log(error);
          this.dialogAnamneseDetail = true;
        });
    },

    async getAnamneseInternacao(item) {
      this.limparAnamneseDetail();
      this.anamnese = {
        id: item.id,
        data: item.data,
        comanda_id: item.comanda_id,
        professional_id: item.professional_id,
        responsavel_id: item.responsavel_id,
        id_anamnese_internacao: item.id_anamnese_internacao,
      };
      this.anamneseInternacao = { id: 0 };

      await this.services.legacyVetusService
        .getAnamneseInternacaobyID(this.anamnese.id_anamnese_internacao)
        .then(async (response) => {
          const data = await response.json();
          data[0].data_exclusao = this.convertDate(data[0].data_exclusao);
          data[0].data = this.convertDate(data[0].data);
          this.anamneseInternacao = data[0];
          this.dialogAnamneseInternacao = true;
        })
        .catch((error) => {
          this.dialogAnamneseInternacao = true;
          console.log(error);
        });
    },

    limparGeral() {
      this.limparPet();
      this.limparPetFile();
      this.limparAnamneseDetail();
      this.limparPets();
      this.limparAnamnese();
      this.limparTutor();
    },

    limparPet() {
      this.pet = {
        id: 0,
        nome: "",
        datadecadastro: "",
        datadenascimento: "",
      };
    },

    limparPetFile() {
      this.petFile = {
        id: 0,
        nome: "",
        datadecadastro: "",
        datadenascimento: "",
      };
    },

    limparAnamneseDetail() {
      this.anamnese = {
        id: 0,
        data: "",
        comanda_id: "",
        professional_id: "",
        responsavel_id: "",
        id_anamnese_internacao: "",
      };
    },

    limparPets() {
      this.listPets = [];
    },

    limparAnamnese() {
      this.listAnamnese = [];
    },

    limparTutor() {
      this.tutor = {
        id: 0,
        nome: "",
        cpfcnpj: "",
        datacadastro: "",
        cidade: "",
      };
    },

    convertDate(date) {
      if (date) {
        const data = new Date(date);
        return (
          data
            .getDay()
            .toString()
            .padStart(2, "0") +
          "/" +
          data
            .getMonth()
            .toString()
            .padStart(2, "0") +
          "/" +
          data.getFullYear()
        );
      } else {
        return "--/--/----";
      }
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}

.headerStyle {
  background-color: #e0e0e0;
}

.fontStyle {
  font-weight: bolder;
  margin-bottom: 0px;
  font-size: 1.1rem;
}
.v-icon--link {
  color: rgb(82, 82, 82) !important;
}
</style>
