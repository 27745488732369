import HTTPService from "@/services/http_service.js";

export default class LojaService extends HTTPService {
  resource = "loja";

  static build() {
    return new LojaService();
  }
  /**
        @returns {Promise<Response>}
     */
  getLojaPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(`${this.resource}`, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  getLojas() {
    return this.get(`lojas`);
  }

  getLojaByUnidadeId(unidade_id) {
    return this.get(`${this.resource}/unidade/${unidade_id}`).then((resp) =>
      resp.json()
    );
  }

  getLojaById(loja_id) {
    return this.get(`${this.resource}/${loja_id}`).then((resp) => resp.json());
  }

  postCreateLoja(responseFunctions, model) {
    // return this.post(`${this.resource}`, JSON.stringify(model));

    this.jsonRequest(responseFunctions, "POST", `${this.resource}/new`, model);
  }

  postUpdateStatusLoja(responseFunctions, model) {
    // return this.post(`${this.resource}`, JSON.stringify(model));

    this.jsonRequest(
      responseFunctions,
      "POST",
      `${this.resource}/changestatus`,
      model
    );
  }

  postUpdateLoja(responseFunctions, model) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      `${this.resource}/update`,
      model
    );
  }
}
