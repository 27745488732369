import HTTPService from "@/services/http_service.js";

export default class VoucherService extends HTTPService {
  resource = "vouchers";

  static build() {
    return new VoucherService();
  }
  /**
        @returns {Promise<Response>}
     */

  // Lista Voucher pelo ID
  getVoucherbyId(id) {
    return this.get(`${this.resource}/${id}`);
  }

  // Lista Voucher pelo Pet ID
  getVoucherbyPetId(id_pet) {
    return this.get(`${this.resource}/pet/list/${id_pet}`);
  }

  // Lista Voucher pelo Unidade ID
  getVoucherbyUnidadeId(id_unidade) {
    return this.get(`${this.resource}/unidade/list/${id_unidade}`);
  }

  // Lista Voucher pelo ID
  getVoucherbySerial(serial) {
    return this.get(`${this.resource}/serial/list/${serial}`);
  }
}
