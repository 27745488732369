<template>
  <div v-if="!loadingInicial">
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-5 font-weight-bold text-left">
          Agendamento de Especialidade
        </h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 form-group clearfix mb-0">
        <v-select
          style="padding-top: 0 !important"
          :items="especialidades"
          v-model="especialidade"
          label="Selecione a Especialidade"
        />
      </div>
      <div class="col-4 form-group clearfix mb-0">
        <v-text-field
          v-model="data"
          placeholder="Data"
          :rules="[rules.required]"
          clear-icon="clear"
          style="margin: 0 5px; padding-top: 5px !important"
          :type="'date'"
          :outlined="false"
        />
      </div>
      <div class="col-1 form-group clearfix mb-0">
        <v-btn
          class="mb-2"
          outlined
          color="#1daf80"
          :disabled="data == '' || especialidadeId == ''"
          @click="pesquisarAgenda"
        >
          buscar
          <v-icon class="ml-2" small> mdi-magnify </v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="!loadTable">
      <div v-if="listaAgenda.length >= 0 && listaAgenda[0] != undefined">
        <div class="row justify-content-end">
          <div class="col-4 text-end">
            <v-template v-for="(valor, index) in historico" :key="index">
              <v-btn
                class="ml-1"
                v-if="validarHistorico(index)"
                color="#1daf80"
                fab
                dark
                outlined
                small
                @click="paginacaoAgenda(valor, index)"
              >
                <font class="btn-number">{{ index + 1 }}</font>
              </v-btn>
              <span class="ml-1" v-if="index == 0 && historico.length >= 4">
                ...
              </span>
            </v-template>
            <v-btn
              v-if="proximaData"
              class="ml-2"
              color="#1daf80"
              fab
              dark
              outlined
              small
              @click="paginacaoAgenda(proximaData)"
            >
              <v-icon small> mdi-arrow-right-bold </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="row">
          <v-card
            elevation="3"
            class="mx-auto"
            width="240"
            v-for="agenda in listaAgenda"
            :key="agenda.dia"
          >
            <v-card-text class="p-0 pb-3">
              <div class="header-day">
                {{ formatarDia(agenda.dia) }}
              </div>
            </v-card-text>
            <div class="px-2">
              <v-card
                elevation="2"
                class="mb-2"
                :class="horario.cliente_id == 0 ? ' bg-create' : ' bg-edit'"
                v-for="horario in agenda.horarios"
                :key="horario.id"
              >
                <v-card-text class="text-start pb-0">
                  <h6 v-if="horario.encaixe == 1">ENCAIXE <br /></h6>
                  <h5>
                    {{ formatarHora(horario.hora_inicio) }} -
                    {{ formatarHora(horario.hora_final) }}
                  </h5>
                  <h6 v-if="horario.cliente_id != 0">
                    Tutor: {{ horario.cliente_nome }} <br />
                    Pet: {{ horario.pet_nome }}<br />
                  </h6>
                </v-card-text>
                <div class="text-end p-2">
                  <v-template v-if="horario.cliente_id == 0">
                    <v-btn
                      v-if="perfilId == 56 || perfilId == 1 || perfilId == 2"
                      class="mx-2"
                      color="#1daf80"
                      fab
                      dark
                      x-small
                      @click="cadastrarConsulta(horario)"
                    >
                      <v-icon dark> mdi-plus </v-icon>
                    </v-btn>
                  </v-template>
                  <v-template v-else class="d-flex justify-content-between">
                    <v-chip
                      :color="statusColor(horario.id_status)"
                      text-color="white"
                    >
                      {{ statusRetorno(horario.id_status) }}
                    </v-chip>
                    <v-btn
                      class="mx-2"
                      color="#1daf80"
                      fab
                      dark
                      x-small
                      @click="verConsulta(horario)"
                    >
                      <v-icon dark> mdi-eye </v-icon>
                    </v-btn>
                  </v-template>
                </div>
              </v-card>
            </div>
          </v-card>
        </div>
      </div>
      <div v-else>
        <h3 v-if="pesquisa" class="text-center">Nenhuma Agenda encontrada!</h3>
      </div>
    </div>
    <div v-else>
      <v-progress-circular
        width="3"
        size="80"
        indeterminate
        color="green"
      ></v-progress-circular>
    </div>
    <div>
      <v-dialog
        persistent
        fullscreen
        v-model="dialogCadastrarConsulta"
        max-width="500px"
      >
        <cadastrar-consulta-especialidade
          :horarioProp="horario"
          v-if="dialogCadastrarConsulta"
          @close="closeDialogCadastrarConsulta"
        />
      </v-dialog>
      <v-dialog persistent v-model="dialogVerConsulta" max-width="500px">
        <ver-consulta-especialidade
          :horarioProp="horario"
          v-if="dialogVerConsulta"
          @close="closeDialogVerConsulta"
        />
      </v-dialog>
    </div>
  </div>
  <div v-else>
    <v-progress-circular
      width="3"
      size="80"
      indeterminate
      color="green"
    ></v-progress-circular>
  </div>
</template>

<script>
import EspecialidadeService from "@/services/especialidade_service.js";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
import cadastrarConsultaEspecialidade from "@/components/agendamento_especialidade_recepcionista/cadastrar_agendamento_especialidade";
import verConsultaEspecialidade from "@/components/agendamento_especialidade_recepcionista/ver_agendamento_especialidade";

export default {
  components: {
    cadastrarConsultaEspecialidade,
    verConsultaEspecialidade,
  },
  data: () => ({
    loadingInicial: false,
    loadTable: false,
    pesquisa: false,
    data: "",
    dataPesquisa: "",
    dataProxima: "",
    dataAnterior: "",
    especialidade: 0,
    especialidadePesquisa: 0,
    especialidades: [],
    dialogCadastrarConsulta: false,
    dialogVerConsulta: false,
    proximaData: "",
    unidadeId: JSON.parse(sessionStorage.vuex).unidade.id,
    perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    services: {
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
      especialidadeService: EspecialidadeService.build(),
    },
    listaAgenda: [],
    historico: [],
    meses: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    statusItems: [
      {
        value: 1,
        text: "A Confirmar",
      },
      {
        value: 2,
        text: "Confirmado",
      },
      {
        value: 3,
        text: "Atendido",
      },
      {
        value: 4,
        text: "Cancelado",
      },
      {
        value: 5,
        text: "Remarcado",
      },
      {
        value: 6,
        text: "Não Compareceu",
      },
    ],
  }),
  mounted() {
    this.getEspecialidadesbyUnidade();
    // this.getEspecialidades();
  },
  computed: {},
  watch: {},
  methods: {
    closeDialogCadastrarConsulta() {
      this.dialogCadastrarConsulta = false;
      this.pesquisarAgenda();
      this.horario = {};
    },
    closeDialogVerConsulta() {
      this.dialogVerConsulta = false;
      this.pesquisarAgenda();
      this.horario = {};
    },
    async pesquisarAgenda() {
      this.loadTable = true;
      this.pesquisa = true;
      this.listaAgenda = [];
      this.historico = [];
      this.especialidadePesquisa = this.especialidade;
      this.dataPesquisa = this.data;
      await this.services.agendaEspecialidadeService
        .getAgendaEspecialidadebyRecepcao(
          this.unidadeId,
          this.especialidadePesquisa,
          this.dataPesquisa
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.listaAgenda = data.agenda;
          this.proximaData = data.proxima_data;
          this.salvarData(this.dataPesquisa);
        })
        .finally(() => {
          this.loadTable = false;
        });
    },
    async paginacaoAgenda(dataPag, index) {
      if (index != undefined) {
        this.limparHistorico(index);
      }
      this.loadTable = true;
      this.listaAgenda = [];
      await this.services.agendaEspecialidadeService
        .getAgendaEspecialidadebyRecepcao(
          this.unidadeId,
          this.especialidadePesquisa,
          dataPag
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.proximaData = data.proxima_data;
          this.listaAgenda = data.agenda;
          this.salvarData(dataPag);
        })
        .finally(() => {
          this.loadTable = false;
        });
    },
    async getEspecialidadesbyUnidade() {
      await this.services.agendaEspecialidadeService
        .getAgendaEspecialidadeByUnidadeId(this.unidadeId)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.especialidades = data.map((especialidade) => {
            return {
              text: especialidade.descricao,
              value: especialidade.id_especialidade,
            };
          });
        });
    },
    cadastrarConsulta(horario) {
      this.horario = horario;
      this.dialogCadastrarConsulta = true;
    },
    verConsulta(horario) {
      this.horario = horario;
      this.dialogVerConsulta = true;
    },
    formatarDia(data) {
      let arrData = data.split("-");
      return `${arrData[2]}/${this.meses[parseInt(arrData[1]) - 1]}`;
    },
    formatarHora(hora) {
      return hora.substring(0, hora.length - 3);
    },
    validarHistorico(index) {
      if (
        index == 0 ||
        index == this.historico.length - 2 ||
        index == this.historico.length - 1
      ) {
        return true;
      }
      return false;
    },
    limparHistorico(valor) {
      valor = valor - 1;
      const limitador = this.historico.length;
      for (let index = valor; index < limitador; index++) {
        this.historico.pop();
      }
    },
    salvarData(dataPesquisada) {
      this.historico.push(dataPesquisada);
    },
    statusRetorno(status) {
      let objStatus = this.statusItems.find(
        (element) => element.value == status
      );
      return objStatus.text;
    },
    statusColor(status) {
      let classStyle = "";
      switch (status) {
        case "1":
          classStyle = "#45268a";
          break;
        case "2":
          classStyle = "#5ed98d";
          break;
        case "3":
          classStyle = "#17773c";
          break;
        case "4":
          classStyle = "#9f1c1c";
          break;
        case "5":
          classStyle = "#4f96a6";
          break;
        case "6":
          classStyle = "#4d4d4d";
          break;
      }
      return classStyle;
    },
  },
};
</script>
<style scoped>
.header-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #1daf80;
  color: white;
  font-weight: bold;
}
.add-btn {
  background-color: #1daf80;
  color: white;
}
.bg-edit {
  background-color: #ffebcf;
}
.bg-create {
  /* background-color: #cff1ff; */
  background-color: white;
}
.btn-number {
  font-weight: bold;
}
</style>
