<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Nova Consulta</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="row justify-content-center mt-5">
      <div class="col-lg-10 text-end form-group clearfix mb-0">
        <v-dialog
          persistent
          fullscreen
          v-model="dialogCadastroTutor"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="allowCadastrarTutorBtn"
              color="#1daf80"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Novo Tutor
              <v-icon class="ms-2">
                mdi-account-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar style="background-color: #1daf80; color: #fff">
              <v-btn icon dark @click="closeDialogCadastrarTutor">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                <span class="headline">Cadastrar Tutor</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <cadastro-novo-paciente
              v-if="dialogCadastroTutor"
              @close="closeDialogCadastrarTutor"
            />
          </v-card>
        </v-dialog>
        <v-dialog
          persistent
          fullscreen
          v-model="dialogCadastrarPet"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="allowCadastrarBtn"
              color="#1daf80"
              dark
              class="mb-2 ml-5"
              v-bind="attrs"
              v-on="on"
            >
              Novo Pet
              <v-icon class="ms-3">
                mdi-paw
              </v-icon>
            </v-btn>
          </template>
          <modal-cadastro-pet
            v-if="dialogCadastrarPet"
            @close="closeDialogCadastrarPet"
          />
        </v-dialog>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="row justify-content-center" v-if="pet_selecionado">
      <div class="col-10">
        <v-alert color="blue" text="white" elevation="2"
          ><div
            class="d-flex"
            style="justify-content: space-evenly!importatnt;"
          >
            <span>
              Pet:
              {{ pet_selecionado.nome_pet }}
            </span>
            <span>
              Tutor:
              {{ pet_selecionado.nome }}
            </span>
            <span>
              Cpf:
              {{ pet_selecionado.cpf }}
            </span>
          </div>
        </v-alert>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-lg-10 form-group clearfix mt-2 mb-0 d-flex">
        <v-select
          class="col-2"
          style="padding-top: 1 !important"
          :items="pesquisaTutorLista"
          :item-text="text"
          :item-value="value"
          v-model="pesquisaTutor"
          label="Tipo Pesquisa"
        />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="pesquisa"
          class="mr-5"
          single-line
          hide-details
        />
        <v-btn
          class="mt-3"
          outlined
          color="#1daf80"
          :disabled="search == ''"
          @click="pesquisar"
        >
          pesquisar
        </v-btn>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="col-lg-10 form-group clearfix mb-0 list-overflow border-list"
        v-if="pets[0] != undefined"
      >
        <div class="d-flex justify-content-end">
          <v-btn color="#1daf80" outlined icon @click="fecharLista()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-list>
          <v-list-item-group
            v-model="pet_selecionado"
            active-class="border"
            color="indigo"
          >
            <v-list-item v-for="(item, i) in pets" :key="i" :value="item">
              <v-list-item-content>
                <v-list-item-title>{{ item.nome_pet }}</v-list-item-title>
                <v-list-item-subtitle
                  >Id Pet: {{ item.id_pet }} | Tutor: {{ item.nome }} | Cpf:
                  {{ item.cpf }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10 form-group clearfix mb-0">
        <v-select
          style="padding-top: 0 !important"
          :items="procedimentos"
          v-model="procedimentoId"
          label="Selcione o Procedimento"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-10 form-group clearfix mb-0">
        <v-textarea
          label="Observação"
          v-model="observacao"
          placeholder=" "
          rows="3"
        ></v-textarea>
      </div>
    </div>

    <v-card-actions>
      <div class="row justify-content-center">
        <div class="col-10 text-end">
          <v-btn
            class="mb-2"
            outlined
            color="#1daf80"
            :disabled="validarCadastro"
            @click="cadastarConsulta"
          >
            Cadastrar
          </v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import modalCadastroPet from "@/components/pets/modal_cadastro";
import PacienteService from "@/services/paciente_service.js";
import PetsService from "@/services/pets_service.js";
import CadastroNovoPaciente from "@/components/pacientes/cadastro_novo_paciente.vue";
import AgendaEspecialidadeService from "@/services/agendamento_especialidade_recepcao_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {
    modalCadastroPet,
    CadastroNovoPaciente,
  },
  props: {
    horarioProp: Object,
  },
  data: () => ({
    loadingInicial: false,
    idCliente: 0,
    clientes: [],
    petId: 0,
    pets: [],
    pet_selecionado: "",
    especialidadeId: 0,
    procedimentoId: 0,
    procedimentos: [],
    dialogCadastrarPet: false,
    dialogCadastroTutor: false,
    validarCadastro: true,
    search: "",
    observacao: "",
    horarioId: 0,
    services: {
      pacienteService: new PacienteService(),
      petService: PetsService.build(),
      agendaEspecialidadeService: AgendaEspecialidadeService.build(),
    },
    pesquisaTutor: 1,
    pesquisaTutorLista: [
      { value: 1, text: "Nome Pet / CPF" },
      { value: 2, text: "Id Pet" },
    ],
  }),
  mounted() {
    this.especialidadeId = this.horarioProp.id_especialidade;
    this.unidadeId = this.horarioProp.id_unidade;
    this.unidadeId = this.horarioProp.id_unidade;
    this.horarioId = this.horarioProp.id;
    this.getProcedimentos();
  },
  computed: {
    allowCadastrarBtn() {
      return 1;
    },
    allowCadastrarTutorBtn() {
      return true;
    },
  },
  watch: {
    search() {
      this.validarform();
    },
    pet_selecionado() {
      this.pets = [];
      this.search = "";
      this.validarform();
    },
    procedimentoId() {
      this.validarform();
    },
  },
  methods: {
    closeDialogCadastrarTutor() {
      this.dialogCadastroTutor = false;
    },
    closeDialogCadastrarPet() {
      this.dialogCadastrarPet = false;
    },
    validarform() {
      if (this.pet_selecionado != undefined && this.procedimentoId != 0) {
        this.validarCadastro = false;
      }
    },
    pesquisar() {
      if (this.pesquisaTutor == 1) {
        this.getPetsByFilter();
      } else {
        this.getPetsById();
      }
    },
    async getPetsById() {
      this.pets = [];
      await this.services.petService
        .getPetById(this.search)
        .then(async (response) => {
          if (response == undefined || response == null) return;
          const data = await response;
          this.pets.push(data);
        })
        .finally(() => {});
    },
    async getPetsByFilter() {
      let filterId = false;
      this.pets = [];
      const paginationParams = {
        page: 1,
        per_page: 1000,
        // search: filterId ? this.searchId : this.search,
        search: this.search,
        filterId,
      };
      await this.services.petService
        .getPetsPaginatedList(paginationParams)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.pets = data.pets;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async getProcedimentos() {
      await this.services.agendaEspecialidadeService
        .getProcesamentobyUnidadeEspecialidade(
          this.unidadeId,
          this.especialidadeId
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.procedimentos = data.procedimentos.map(
            ({ id_procedimento, procedimento }) => {
              return {
                text: procedimento,
                value: parseInt(id_procedimento),
              };
            }
          );
        });
    },
    getModel() {
      return {
        id_agenda_especialidade: parseInt(this.horarioId),
        id_procedimento: this.procedimentoId,
        id_pet: this.pet_selecionado.id_pet,
        observacao: this.observacao,
      };
    },
    async cadastarConsulta() {
      await this.services.agendaEspecialidadeService
        .postAgendamentoRecepcao(this.getModel())
        .then((response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao agendar consulta",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Consulta agendada com sucesso",
            color: "sucess",
          });
          this.$emit("close");
        });
    },
    fecharLista() {
      this.pets = [];
    },
    enviarValor(item) {
      this.pet_selecionado = item;
    },
  },
};
</script>

<style scoped>
.list-overflow {
  max-height: 300px;
  overflow-y: auto;
}
.border-list {
  box-shadow: 0px 5px 10px #0000001f;
}
</style>
