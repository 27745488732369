import HTTPService from "@/services/http_service.js";

export default class TelevetService extends HTTPService {
  resource = "loja/compra/pagsegurocartao";
  resourcePagSeg = "https://sandbox.api.pagseguro.com";

  static build() {
    return new TelevetService();
  }
  /**
        @returns {Promise<Response>}
     */

  // voucher no agendamento
  encerrarConsulta(responseFunctions, model) {
    // - id_agendamento
    // - id_status

    // 0 -> Pendente
    // 1 -> Sala Aberta
    // 2 -> Concluido
    // 3 -> Não compareceu
    // 4 -> Desistência
    // 5 -> Tutor perdeu a conexao
    // 6 -> Instabilidade de conexão

    this.jsonRequest(responseFunctions, "POST", `sala-video/encerra`, model);
  }
}
