<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Prontuários</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pacientes"
      :options.sync="options"
      :server-items-length="totalList"
      sort-by="nome"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat max-width="100%">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            class="col-5"
            single-line
            hide-details
          ></v-text-field>

          <!-- DIALOG FOTOS CLASSIFICADAS -->
          <v-dialog
            v-model="dialogFotosClassificadas"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <fotos-classificadas
              :paciente="$_GETTERS_usuario.idPaciente"
              :key="rederComp"
              @close="close()"
            />
          </v-dialog>
          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <prontuarios :consultas="prontuarios" @close="close()" />
          </v-dialog>

          <!-- DIALOG DE PRONTUÁRIO -->
          <v-dialog
            v-model="dialogGaleria"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <galerias :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
            <v-card color="white">
              <v-card-text>
                Carregando...
                <v-progress-linear
                  indeterminate
                  color="green lighten-3"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogArquivo"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <arquivo :paciente="paciente" :key="rederComp" @close="close()" />
          </v-dialog>
          <v-dialog
            v-model="dialogAtestado"
            hide-overlay
            persistent
            width="50%"
          >
            <atestado @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogExames"
            hide-overlay
            persistent
            style="width: 50%"
          >
            <exames @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogEncaminhamento"
            hide-overlay
            persistent
            width="30%"
          >
            <encaminhamento @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogReceita" hide-overlay persistent width="50%">
            <receita @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog v-model="dialogTermo" hide-overlay persistent width="40%">
            <termos @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogReceitaCarbonada"
            hide-overlay
            persistent
            width="50%"
          >
            <receita-carbonada @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          title="Fotos classificadas"
          @click="listFotosClassificadas(item)"
          >fa-solid fa-bookmark
        </v-icon>

        <v-icon
          small
          class="mr-2"
          title="Prontuários"
          @click="listProntuarios(item)"
          >fas fa-file-medical-alt
        </v-icon>
      </template>
    </v-data-table>

    <!-- EXAMES PDF -->
    <div id="examesPDF" ref="examesPDF" v-show="false">
      <div id="header" v-if="htmlToPdf != 'receita-carbonada'">
        <!-- <img src="@/assets/images/logo-horizontal.png" alt="" /> -->
        <img src="@/assets/images/Logotipo-Televet-Play-Color.png" alt="" />
      </div>
      <div id="main">
        <!-- DOC EXAMES -->
        <div class="d-flex flex-column w-100" v-if="htmlToPdf == 'exames'">
          <p>
            PARA:<span class="space" style="letter-spacing: 5px">_</span
            >{{ nome }}
          </p>
          <p><b>Solicito:</b></p>
          <u v-for="(exame, i) in exames" :key="i">
            <li class="text-nowrap resp">{{ i + 1 }} {{ exame }}</li>
          </u>
          <div>
            <p>OBS.:<span class="space">_</span>{{ obs }}</p>
            <p v-if="obs2 != ''">OBS.:<span class="space">_</span>{{ obs2 }}</p>
            <p class="d-flex flex-column ml-auto">
              <span> _________________________________________</span>
              <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
              <span class="ml-1 resp"
                >Cirurgião Plástico - CRM 6110 - RQE 2395</span
              >
            </p>
          </div>
        </div>
        <!-- DOC ATESTADO -->
        <div v-else-if="htmlToPdf == 'atestado'">
          <p>Atestado Médico</p>
          <p class="indent resp">
            Atesto para devidos fins que <b>{{ nome }}</b> , necessita
            afastar-se de suas atividades laborais por um período de
            <b>{{ dias }}</b> dias, por motivo de procedimento cirúrgico.
          </p>

          <p>CID: {{ codigoCFM }}</p>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column ml-auto">
              <span> _________________________________________</span>
              <span class="ml-1"
                >Luiz Nestor Vasconcelos Rodrigues - CRM 6110
              </span>
            </p>
          </div>
        </div>
        <!-- DOC RECEITA -->
        <div
          class="d-flex flex-column w-100 resp"
          v-else-if="htmlToPdf == 'receita'"
        >
          <div>
            <p>
              PARA:<span class="space">_</span><b>{{ nome }}</b>
            </p>
            <p class="m-auto text-center"><b>Medicações</b></p>
          </div>
          <u v-for="(item, i) in receita" :key="i">
            <li class="d-flex flex-column resp">
              <p class="m-auto text-center" v-if="item.titulo">
                <strong
                  ><u>{{ item.titulo }}</u></strong
                >
              </p>
              <p class="resp">
                {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
              </p>
            </li>
          </u>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
          </div>
          <p class="d-flex flex-column ml-auto">
            <span> _________________________________________</span>
            <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
            <span class="ml-1 resp"
              >Cirurgião Plástico - CRM 6110 - RQE 2395</span
            >
          </p>
        </div>
        <!-- DOC RECEITA CARBONADA -->
        <div class="carbonada" v-else-if="htmlToPdf == 'receita-carbonada'">
          <div class="row row-cols-2" style="width:18rem">
            <div
              class="col"
              style="border-right: solid gray 0.01px !important;"
            >
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="border: solid gray 0.1px !important;width: 4.5rem !important;  padding-right: 1px;padding-left: 1px;"
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px;"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px;"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px ">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
            <div class="col">
              <b>RECEITUÁRIO DE CONTROLE ESPECIAL</b><br />
              <div class="row row-cols-2 resp">
                <div class="resp col col-8 pt-6">
                  <div
                    class="resp"
                    style="border: solid gray 0.1px !important;width: 4.5rem !important;  padding-right: 1px;padding-left: 1px;"
                  >
                    <span
                      class="ml-1 mt-2 pb-0 resp"
                      style="padding-top: 1px;"
                    ></span
                    ><br />
                    <span class="ml-1 mt-2 pb-0 resp" style="padding-top: 1px;"
                      >IDENTIFICAÇÃO DO EMITENTE</span
                    ><br />
                    <span class="text-decoration-underline resp p-0 mt-0"
                      >__________________________________________________________</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      ><b>Dr. Luiz Nestor Vasconcelos Rodrigues</b></span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >Cirurgia Plástica - CRM 6110 - RQE 2395</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >End.: Riomar Trade Center</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >Rua Desembargador Lauro Nogueira, 1500. Papicu</span
                    >
                    <br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px ">
                      10º andar - sala 1017</span
                    ><br />
                    <span style="letter-spacing: 0.2px; font-size:2.6px "
                      >Fone:(85) 99985.1522 / (85)3039.3890</span
                    ><br />
                  </div>
                </div>
                <div class="resp col col-4 p-0 pt-2">
                  1º via Farmácia <br />
                  2º via Farmácia <br /><br /><br /><br />
                  ___________________________________ <br />
                  Carimbo e Assinatura <br />
                </div>
              </div>
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0"
            style="width:18rem;  padding-top: 0px"
          >
            <div
              class="col"
              style="border-right: solid gray 0.01px !important; padding-top: 0px"
            >
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
            <div class="col" style="padding-top: 0px">
              Paciente: <b>{{ nome }}</b
              ><br />
              Endereço: {{ logradouro }}, {{ numero }}. {{ complemento }} <br />
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  {{ i + 1 }} - {{ item.medicamento }}_______{{ item.quantidade
                  }}<br />&nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li> </u
              ><br /><br />
              <span class="resp pb-0 mb-0"
                >DATA: {{ new Date().toLocaleDateString() }}</span
              >
            </div>
          </div>
          <div
            class="row rows-cols-2 pt-0 pr-0"
            style="width:18rem;  padding: 0px"
          >
            <div
              class="col"
              style="border-right: solid gray 0.01px !important;margin-left:1px"
            >
              <div
                class="row rows-cols-2"
                style="border-top: solid gray 0.01px !important;border-left: solid gray 0.01px !important;border-bottom: solid gray 0.01px !important;"
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important;"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right:0px">
                  <span>
                    IDENTIFICAÇÃO DO FORNECEDOR
                  </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="row rows-cols-2"
                style="border-top: solid gray 0.01px !important;border-right: solid gray 0.01px !important;border-bottom: solid gray 0.01px !important;"
              >
                <div
                  class="col pt-0"
                  style="border-right: solid gray 0.01px !important;"
                >
                  <span>IDENTIFICAÇÃO DO COMPRADOR</span><br />
                  <br /><br /><br />
                  <span>Nome Completo_______________________</span><br />
                  <span>_______________________________________</span><br />
                  <span>IDENT:______________ORG.EMISSOR______</span><br />
                  <span>END:__________________________________</span><br />
                  <span>______________________________________</span><br />
                  <span>Cidade:______________UF:_______________</span><br />
                  <span>Tel:_____________________________</span><br />
                </div>
                <div class="col pt-0 pl-1" style="padding-right:0px">
                  <span>
                    IDENTIFICAÇÃO DO FORNECEDOR
                  </span>
                  <br /><br /><br /><br /><br /><br /><br /><br />
                  <span>______________________________</span><br />
                  <span class="ml-1">Assinatura Farmacêutico</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DOC TERMOS -->
        <div
          class="d-flex flex-column w-100 resp"
          v-else-if="htmlToPdf == 'termo'"
        >
          <div style="height: 4vh; width: 4vw" v-if="tipoTermo == 'termo_um'">
            <img src="@/assets/images/SBCP.jpg" alt="" />
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_um'">
            <b>TERMO DE RESPONSABILIDADE EXPRESSO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_um'">
            O (a) paciente ou responsável pelo paciente, Sr. (a)
            <b>{{ nome }}</b
            >, sob o CPF:{{ cpf }}, aqui declarado, dá plena autorização ao seu
            médico assistente, Dr. LUIZ NESTOR VASCONCELOS RODRIGUES C.R.M. Nº.
            6110, para fazer as investigações necessárias ao diagnóstico e
            executar tratamentos, operações, anestesias, transfusões de sangue,
            ou outras condutas médicas que venham de encontro às necessidades
            clínico-cirúrgicas do caso, bem como dá ciência de informações sobre
            sua capacidade técnico profissional e moral, comprometendo-se a
            respeitar as instruções que lhe forem fornecidas, inclusive quanto
            aos imprevistos oriundos da eventualidade como infecções, necroses
            de retalhos ou regiões operadas, assimetrias, deiscências, seromas,
            rejeições, no caso de próteses, embolia pulmonar, trombose venosa
            profunda, entre outras complicações inerentes aos procedimentos.
            Outrossim, sou consciente dos riscos informados, incluindo o de
            morte, ou da não observância das orientações médicas
            recomendadas.<br />
            CIRURGIA (S) PROPOSTAS (S): (indicação):<br />
            {{ procedimento }}
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_um'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Assinatura paciente ou responsável legal) </span>
            </p>
            <p class="d-flex flex-column mr-auto resp">
              NOME POR EXTENSO: {{ nome }}<br />
              RG. Ou CPF.: {{ cpf }}
            </p>
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_dois'">
            <b>TERMO DE CONSENTIMENTO INFORMADO EXPRESSO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_dois'">
            Declaro que recebi o "TERMO DE INFORMAÇÕES PRÉ-CIRÚRGICAS" como
            também todos os esclarecimentos necessários sobre as informações
            específicas contidas neste termo sobre a cirurgia a ser realizada,
            onde foram explicados os detalhes sobre as cicatrizes resultantes,
            outros estados orgânicos, eventuais intercorrências, evoluções
            favoráveis e desfavoráveis pós-cirúrgicas, infecções, deiscências,
            necroses, assimetrias, perda da sensibilidade, rejeições, no caso de
            próteses, embolia pulmonar, trombose venosa profunda, inclusive
            risco de morte, entre outras complicações do procedimento bem como
            os cuidados para minimizar esses problemas. Assim, livremente e de
            espontânea vontade, assino (amos) o presente TERMO DE CONSENTIMENTO
            PÓS-INFORMADO para que o Dr. LUIZ NESTOR VASCONCELOS RODRIGUES,
            C.R.M. Nº. 6110 possa realizar o procedimento clínico-cirúrgico de e
            para que produza os devidos efeitos legais.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_dois'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>Paciente</span>
            </p>
            <p class="d-flex flex-column mr-auto">
              <span> _________________________________________</span>
              <span>(Responsável legal, qdo.menor)</span>
            </p>
          </div>
          <p class="resp text-center" v-if="tipoTermo == 'termo_tres'">
            <b>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</b>
          </p>
          <p class="resp indent" v-if="tipoTermo == 'termo_tres'">
            Eu <b>{{ nome }}</b
            >, Declaro para devidos fins que estou ciente de que realizarei
            procedimento cirúrgico em vigência da pandemia de infecção pelo
            COVID-19, com potencial risco de infecção, uma vez que a infecção é
            comunitária. Também estou ciente que o procedimento que serei
            submetida <b>{{ procedimento }}</b
            >, em riscos anestésico cirúrgicas, tendo potencial necessidade de
            internação em unidade de terapia intensiva (U.T.I.), que, no momento
            estão sendo utilizadas para pacientes portadores de COVID-19 (CORONA
            VÍRUS). Portando, estou ciente de que, em caso de internação em
            U.T.I., estarei em um ambiente sem isolamento e em contato próximo
            com pacientes com COVID -19. Tendo alto risco de contaminação por
            este vírus.
          </p>
          <div class="d-flex flex-column" v-if="tipoTermo == 'termo_tres'">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <div class="d-flex flex-row justify-space-between">
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-1">Assinatura Paciente/ Responsável</span><br />
                <span class="ml-1"> CPF: {{ cpf }}</span>
              </p>
              <p class="d-flex flex-column mr-auto">
                <span> _____________________________________</span>
                <span class="ml-3">Testemunha</span><br />
                CPF:______________________________
              </p>
            </div>
          </div>
        </div>
        <!-- DOC ENCAMINHAMENTO -->
        <div
          class="d-flex flex-column"
          v-else-if="htmlToPdf == 'encaminhamento'"
        >
          <p class="resp">{{ nomeMedico }}</p>
          <p class="resp">
            Encaminho o(a) paciente <b>{{ nome }}</b
            >, para avaliação e conduta que julgar necessária.
          </p>
          <p class="ml-auto">Agradeço a atenção.</p>
          <div class="d-flex flex-column text-end pt-8">
            <p class="ml-auto">
              Fortaleza, {{ gerarData().dia
              }}<span class="space">_</span>de<span class="space">_</span
              >{{ gerarData().mes }}<span class="space">_</span
              >{{ gerarData().ano }}.
            </p>
            <p class="d-flex flex-column ml-auto">
              <span> _________________________________________</span>
              <span class="ml-2">Luiz Nestor Vasconcelos Rodrigues </span>
              <span class="ml-1 resp"
                >Cirurgião Plástico - CRM 6110 - RQE 2395</span
              >
            </p>
          </div>
        </div>
      </div>
      <div id="footer" v-if="htmlToPdf != 'receita-carbonada'">
        <img src="@/assets/images/footer.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Atestado from "@/components/documentos/atestado.vue";
import Encaminhamento from "@/components/documentos/encaminhamento.vue";
import Exames from "@/components/documentos/exames.vue";
import Receita from "@/components/documentos/receita.vue";
import ReceitaCarbonada from "@/components/documentos/receita_carbonada.vue";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import ProfissionalService from "@/services/paciente_service.js";
import ConsultaService from "@/services/consulta_service.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Termos from "@/components/documentos/termos.vue";
import Galerias from "@/components/prontuarios/galeria.vue";
import FotosClassificadas from "@/components/prontuarios/fotos_classificadas.vue";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: {
    Prontuarios,
    Galerias,
    Arquivo,
    Atestado,
    Exames,
    Encaminhamento,
    Receita,
    ReceitaCarbonada,
    Termos,
    FotosClassificadas,
  },
  mixins: [Mixin],
  beforeMount() {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
    }
  },
  data: () => ({
    rederComp: 0,
    dialog: false,
    loading: false,
    dialogLoading: false,
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    dialogCadastro: false,
    dialogGaleria: false,
    dialogFotosClassificadas: false,
    paciente: {},
    visualizar: false,
    pacientes: [],
    prontuarios: [],
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "CPF", value: "cpf" },
      { text: "Email", value: "email" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    htmlToPdf: "",
    // Documentos
    arquivos: [],
    dialogArquivo: false,
    // Atestado
    dialogAtestado: false,
    nome: "",
    dias: "",
    codigoCFM: "",
    //Encaminhamento
    dialogEncaminhamento: false,
    nomeMedico: "",
    //Solicitar Exame
    dialogExames: false,
    exames: [],
    obs: "",
    obs2: "",
    //Receita Nova
    dialogReceita: false,
    receita: "",
    //Receita Carbonada
    dialogReceitaCarbonada: false,
    logradouro: "",
    numero: "",
    complemento: "",
    //Termos
    dialogTermo: false,
    tipoTermo: "",
    procedimento: "",
    cpf: "",
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    search() {
      this.atualizarListaPacientes();
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarListaPacientes();
      },
      deep: true,
    },
  },

  mounted() {
    this.atualizarListaPacientes();
  },
  methods: {
    forceRender() {
      this.rederComp += 1;
    },
    atualizarListaPacientes() {
      const profissionalService = new ProfissionalService();
      this.loading = true;
      this.pacientes = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.totalList = 1;
          this.pacientes = [body.data];
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      profissionalService.get(
        {
          onSucess,
          onError,
          onEnd,
        },
        this.$_GETTERS_usuario.idPaciente
      );
    },
    gerarAtestado(item) {
      this.nome = item.nome;
      this.dialogAtestado = true;
    },
    gerarEncaminhamento(item) {
      this.nome = item.nome;
      this.dialogEncaminhamento = true;
    },
    gerarSolicitacaoDeExame(item) {
      this.nome = item.nome;
      this.dialogExames = true;
    },
    gerarReceitasNovas(item) {
      this.nome = item.nome;
      this.dialogReceita = true;
    },
    gerarReceitasCarbonada(item) {
      this.nome = item.nome;
      this.logradouro = item.logradouro;
      this.numero = item.numero;
      this.complemento = item.complemento;
      this.dialogReceitaCarbonada = true;
    },
    gerarTermos(item) {
      this.nome = item.nome;
      this.cpf = item.cpf;
      this.dialogTermo = true;
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      let dataFormatada = {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
      return dataFormatada;
    },

    gerarPDF(info) {
      switch (info.doc) {
        case "atestado":
          this.htmlToPdf = info.doc;
          this.dias = info.dias;
          this.codigoCFM = info.codigoCFM;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, "Atestado", "padrao");
          });
          break;
        case "termo":
          this.htmlToPdf = info.doc;
          this.tipoTermo = info.termo_tipo;
          this.procedimento = info.procedimento;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Termo de Responsabilidade",
              "padrao"
            );
          });
          break;
        case "receita":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, "Receita", "padrao");
          });
          break;
        case "receita-carbonada":
          this.htmlToPdf = info.doc;
          this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Receita-carbonada",
              "carbonada"
            );
          });
          break;
        case "encaminhamento":
          this.htmlToPdf = info.doc;
          this.nomeMedico = info.nomeMedico;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Encaminhamento",
              "padrao"
            );
          });
          break;
        case "exames":
          this.htmlToPdf = info.doc;
          this.exames = info.exames;
          this.obs = info.obs;
          this.obs2 = info.obs2;
          this.$nextTick(() => {
            this.close();
            this.criarPDF(
              this.$refs.examesPDF.innerHTML,
              "Solicitação de exames",
              "padrao"
            );
          });
          break;

        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        var doc = new jsPDF("p", "mm", "a4");
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else {
        var docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },

    listProntuarios() {
      this.paciente = this.$_GETTERS_usuario;
      this.dialogLoading = true;
      const consultaService = new ConsultaService();
      consultaService.getListByPacienteId(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.prontuarios = body.data.consultas;
              this.dialog = true;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogLoading = false;
          },
        },
        this.$_GETTERS_usuario.idPaciente
      );
    },

    listFile(item) {
      this.paciente = item;
      this.dialogArquivo = true;
      this.forceRender();
    },

    listGalerias(item) {
      this.paciente = item;
      this.dialogGaleria = true;
      this.forceRender();
    },

    listFotosClassificadas() {
      this.paciente = this.$_GETTERS_usuario.idPaciente;
      this.dialogFotosClassificadas = true;
      this.forceRender();
    },

    close() {
      this.dialog = false;
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
      this.dialogAtestado = false;
      this.dialogEncaminhamento = false;
      this.dialogExames = false;
      this.dialogReceita = false;
      this.dialogReceitaCarbonada = false;
      this.dialogTermo = false;
      this.dialogArquivo = false;
      this.forceRender();
    },
  },
};
</script>
<style scoped>
#examesPDF {
  text-align: center;
}
#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header {
  height: 1rem;
  /* background-color: red; */
}
#header img {
  height: 1rem;
  width: 2.5rem;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding: 1rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.resp {
  letter-spacing: 0.2px;
}
.indent {
  text-indent: 5px;
}
</style>
