<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Cadastrar Pré Agendamento</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="nome">Nome do Cliente</label>
          <v-text-field
            v-model="clienteNome"
            :rules="[rules.required]"
            placeholder="Nome do Cliente"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="nome">Telefone do Cliente</label>
          <v-text-field
            v-model="telefoneCliente"
            :rules="[rules.required]"
            placeholder="Telefone do Cliente"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Especialidade</label>
          <v-select
            style="padding-top: 0 !important"
            :items="especialidadeList"
            :rules="[rules.required]"
            v-model="especialidadeId"
          />
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label for="nome">Data</label>
          <v-text-field
            v-model="data"
            placeholder="Data"
            :rules="[rules.required]"
            clear-icon="clear"
            style="margin: 0 5px; padding-top: 5px !important"
            :type="'date'"
            :outlined="false"
          />
          <span class="text-muted">ex: "Dia/Mês/Ano"</span>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Horário</label>
          <v-select
            style="padding-top: 0 !important"
            :items="horarios"
            :rules="[rules.required]"
            v-model="agendaEspecialidadeId"
          />
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrar"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import CentroCustoService from "@/services/centro_custo_service";
import EspecialidadeService from "@/services/especialidade_service.js";
import AgendaService from "@/services/agenda_service.js";
import preAgendamentoService from "@/services/preagendamento_service.js";

export default {
  mixins: [Mixin],
  data: () => ({
    formCadastro: "",
    clienteNome: "",
    telefoneCliente: "",
    especialidadeList: [],
    agendaEspecialidadeId: 0,
    horarios: [],
    especialidadeId: 0,
    data: "",
    loading: false,
    services: {
      centroCustoService: CentroCustoService.build(),
      especialidadeService: EspecialidadeService.build(),
      preAgendamentoService: preAgendamentoService.build(),
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  mounted() {
    this.formCadastro = document.forms[0];
    this.setEspecialidades();
  },
  methods: {
    async setEspecialidades() {
      return await this.services.especialidadeService
        .getEspecialidadeList()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { especialidades } = data;
          this.especialidadeList = especialidades.map(({ id, descricao }) => {
            return {
              value: parseInt(id),
              text: descricao,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    cadastrar() {
      this.loading = true;
      this.services.preAgendamentoService
        .register(this.getFormData())
        .then(({ status }) => {
          this.loading = false;
          this.mostrarFeedback(status);
          if (status === 200) {
            this.resetForm();
            this.$emit("response");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarFeedback(status) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status === 200
            ? "Pré Agendamento cadastrado com sucesso!"
            : status === 400
            ? "Preencha os dados Corretamente"
            : this.$global.messages.internalServerError,
        color: status === 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.clienteNome = "";
    },
    getFormData() {
      const formData = new FormData();
      formData.append("cliente_nome", this.clienteNome);
      formData.append("cliente_telefone", this.telefoneCliente);
      formData.append("id_especialidade", this.especialidadeId);
      formData.append("id_agenda_especialidade", this.agendaEspecialidadeId);
      return formData;
    },
    atualizarAgendamentos(filtro = {}) {
      const agendaService = new AgendaService();
      this.loading = true;
      this.horarios = [];
      agendaService.getAgendaEspecialidadeList(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pagination = body.data.pagination;
              this.horarios = body.data.agendas.map((horario) => {
                return {
                  value: parseInt(horario.id),
                  text: horario.hora_inicio + " - " + horario.hora_final,
                };
              });
              console.log(this.horarios);
            } else {
              console.error("error");
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        filtro
      );
    },
  },
  watch: {
    especialidadeId(value) {
      if (value === 0) return;
      if (this.data === "") return;
      this.atualizarAgendamentos({
        id_especialidade: value,
        data_inicio: this.data,
        data_fim: this.data,
      });
    },
    data(value) {
      if (value === "") return;
      if (this.especialidadeId === 0) return;
      this.atualizarAgendamentos({
        id_especialidade: this.especialidadeId,
        data_inicio: value,
        data_fim: value,
      });
    },
  },
};
</script>
