<template>
  <div>
    <div class="p-lg-3 pb-1">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Filas de atendimento
        </h3>
      </div>
      <div class="btnEscolherfila">
        <v-btn
          small
          depressed
          class="bg-verde"
          dark
          rounded
          @click="dialogLocal"
        >
          <span v-if="!loading">Local de atendimento</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
        <v-btn
          small
          depressed
          text
          :class="{ btn: loading }"
          style="margin-left: 1rem;"
          @click="escolherEspecialidade"
        >
          <span>Especialidade</span>
        </v-btn>
      </div>
      <v-dialog
        v-model="dialogAlocarLocal"
        v-if="dialogAlocarLocal"
        max-width="500px"
      >
        <v-card>
          <v-card-title>Selecionar local de atendimento</v-card-title>
          <v-card-text class="">
            <v-select
              :items="selectFilas"
              v-model="filaAlocada"
              :rules="[
                validationService.required('Campo obrigatório.'),
                validationService.isNotEmpty(),
              ]"
              label="Selecione onde deseja atender"
              item-value="text"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="gray" text @click="dialogAlocarLocal = false">
              Fechar
            </v-btn>
            <v-btn
              small
              depressed
              color="warning"
              :disabled="!filaAlocada"
              @click="alocarLocal"
              class="dialogButtonEncaminhar"
            >
              <span v-if="!loadingAlocar">Alocar</span>
              <v-progress-circular
                v-else
                :width="3"
                :size="20"
                indeterminate
                color="white"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogEspecialidade"
        v-if="dialogEspecialidade"
        max-width="500px"
      >
        <v-card>
          <v-card-title>Especialidade</v-card-title>
          <v-card-text class="">
            <v-autocomplete
              :items="filas"
              v-model="filaAtendimento"
              :rules="[
                validationService.required('Campo obrigatório.'),
                validationService.isNotEmpty(),
              ]"
              label="Selecione qual especialidade deseja atender"
              return-object
              item-text="Nome"
              :loading="loadingFilas"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="gray" text @click="dialogEspecialidade = false">
              Fechar
            </v-btn>
            <v-btn
              small
              depressed
              color="success"
              :disabled="!filaAtendimento"
              @click="selecionarFila"
              class="dialogButtonEncaminhar"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="d-flex ml-1" v-if="localAtendimento">
      <span>Local de atendimento: {{ localAtendimento }}</span>
    </div>
    <hr />

    <div class="container">
      <div class="d-flex align-items-center">
        <div v-if="infoPaciente.Senha" class="col-3 display-senha">
          <h2>senha</h2>
          <h1 style="font-size:60px;">
            {{ infoPaciente.Senha }}
          </h1>
        </div>
        <div v-else>
          <h2>senha</h2>
          <h1 style="font-size:60px;">
            -
          </h1>
        </div>
        <InfoChamada :key="infoChamadaKey" />
      </div>
      <div class="buttons d-flex justify-content-center" style="gap: 0.5rem">
        <v-btn
          small
          depressed
          :disabled="infoPaciente.Senha || !id_filaAlocada"
          color="success"
          :class="{ btn: loading }"
          @click="chamar"
        >
          <span v-if="!loading">Chamar</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          v-if="infoPaciente.PetCodigo"
          small
          depressed
          color="success"
          @click="atender"
        >
          Atender
          <!-- <v-progress-circular
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular> -->
        </v-btn>

        <v-btn
          small
          depressed
          :disabled="!id_filaAlocada"
          color="primary"
          :class="{ btn: loading }"
          @click="tocar"
        >
          <span v-if="!loading">Tocar</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-btn
          small
          depressed
          :disabled="!id_filaAlocada"
          color="warning"
          :class="{ btn: loading }"
          @click="encaminhar"
        >
          <span v-if="!loading">Encaminhar</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-dialog v-model="dialogEncaminhar" max-width="500px">
          <v-card>
            <v-card-title>Encaminhar</v-card-title>
            <!-- <v-card-text>
              <v-autocomplete
                v-model="selectedTutor"
                :loading="loadingSearch"
                :items="tutores"
                :search-input.sync="searchTutor"
                hide-no-data
                hide-selected
                label="Selecione um tutor"
                item-text="nome"
                return-object
              ></v-autocomplete>
            </v-card-text> -->

            <v-card-text>
              <!-- <v-select
                :items="pets"
                v-model="selectedPet"
                label="Selecione um pet"
                item-text="nome"
                return-object
              ></v-select> -->
              <v-text-field
                :value="infoPaciente.NomePet"
                label="Pet"
                disabled
              ></v-text-field>
            </v-card-text>

            <v-card-text>
              <v-radio-group v-model="urgencia" row>
                <v-radio value="1"></v-radio>
                <span class="ml-n5 mt-1">Normal</span>
                <v-radio value="4" class="ml-8"></v-radio>
                <span class="ml-n5 mt-1">Urgente</span>
              </v-radio-group>
            </v-card-text>

            <textarea
              rows="4"
              cols="57"
              class="p-2 border mb-5"
              placeholder="Observações"
              maxlength="400"
              v-model="observacoes"
            ></textarea>

            <v-card-text>
              <v-autocomplete
                :items="selectEncaminhar"
                v-model="especialidadeSelecionada"
                label="Selecione para onde deseja encaminhar"
                item-value="text"
              ></v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn color="gray" text @click="dialogEncaminhar = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                color="warning"
                :disabled="!especialidadeSelecionada || loadingEncaminhar"
                @click="encaminharPara"
                class="dialogButtonEncaminhar"
              >
                <span v-if="!loadingEncaminhar">Encaminhar</span>
                <v-progress-circular
                  v-else
                  :width="3"
                  :size="20"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- <v-btn
          small
          depressed
          :disabled="!id_filaAlocada"
          color="error"
          :class="{ btn: loading }"
          @click="finalizar"
        >
          <span v-if="!loading">Finalizar</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="25"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn> -->

        <v-btn
          small
          depressed
          :disabled="!id_filaAlocada"
          color="primary"
          :class="{ btn: loading }"
          @click="aguardar"
        >
          <span v-if="!loading">Aguardar</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="25"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </div>
    </div>

    <div>
      <div class="d-flex ml-2" v-if="filaEspecialidadeTitulo">
        <h4>
          Especialidade de atendimento:
          <b class="font-weight-regular">{{ filaEspecialidadeTitulo }}</b>
        </h4>
      </div>
      <Fila :key="infoChamadaKey" @chamarSenha="chamarSenhaLista($event)" />
    </div>

    <v-dialog
      max-width="500px"
      persistent
      fullscreen
      v-if="dialogAtender"
      v-model="dialogAtender"
    >
      <modal-atender
        v-if="dialogAtender"
        :petCodigo="petCodigo"
        :petNome="petNome"
        :unidadeId="unidadeId"
        @close="dialogAtender = false"
      />
      <v-card> </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import Mixin from "@/mixins/vuex_mixin.js";
import axios from "axios";

import InfoChamada from "@/components/recepcao_medico/infoChamada/infoChamada.vue";
import Fila from "@/components/fila_atendimento/fila.vue";
import ValidationService from "@/services/validation_service.js";
import ModalAtender from "@/components/fila_atendimento/modal_atender";
import PacienteService from "@/services/paciente_service.js";
import PetService from "../services/pets_service.js";

export default {
  components: {
    InfoChamada,
    Fila,
    ModalAtender,
  },
  mixins: [Mixin],
  data() {
    return {
      unidadeId: 0,
      idRequisicaoProcedimento: 0,
      filas: "",
      filaEspecialidadeTitulo: "",
      filaAtendimento: "",
      dialogEspecialidade: false,
      loadingFilas: false,
      loadingAlocar: false,
      loading: false,
      loadingSearch: false,
      tokenChamefacil: "",
      idSenha: "",
      infoPaciente: {},
      observacoes: "",
      infoChamadaKey: 0,
      dialogAlocarLocal: false,
      selectFilas: [],
      filaAlocada: "",
      id_filaAlocada: "",
      idChamadaInterna: 0,
      dialogEncaminhar: false,
      loadingEncaminhar: false,
      tutor_id: "",
      pet_id: "",
      pets: [],
      selectedPet: "",
      tutores: [],
      selectedTutor: "",
      searchTutor: "",
      selectEncaminhar: [],
      especialidadeSelecionada: "",
      encaminharSelecionado: "",
      validationService: {},
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      dialogAtender: false,
      petCodigo: 0,
      petNome: "",
      localAtendimento: "",
      urgencia: "1",
    };
  },
  watch: {
    searchTutor(nome) {
      if (!nome || this.selectedTutor) {
        return;
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
              console.log(this.tutores);
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    selectedTutor() {
      // this.selectedPet = "";
      this.pets = this.selectedTutor.pets;
      console.log(this.pets);
    },
  },
  beforeMount() {
    window.onbeforeunload = function() {
      return "";
    };
    this.id_filaAlocada = localStorage.getItem("idFilaAlocada");
    this.localAtendimento = JSON.parse(
      localStorage.getItem("localAtendimento")
    );
    this.filaEspecialidadeTitulo = JSON.parse(
      localStorage.getItem("especialidadeAtendimento")
    );
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
    this.infoPaciente = !localStorage.getItem("infoPaciente")
      ? {}
      : JSON.parse(localStorage.getItem("infoPaciente"));
    this.validationService = ValidationService;
    this.unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;

    if (!this.id_filaAlocada) this.dialogLocal();
  },
  methods: {
    async escolherEspecialidade() {
      await this.listarFilasAtendimento();
      this.dialogEspecialidade = true;
    },
    listarFilasAtendimento() {
      this.loadingFilas = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/filainterna/listarPorLojaUsuarioLogado",
          {
            headers,
          }
        )
        .then(async (resp) => {
          this.filas = resp.data.Filas;
        })
        .finally(() => {
          this.loadingFilas = false;
        });
    },
    selecionarFila() {
      console.log(this.filaAtendimento);
      const headers = { token: this.tokenChamefacil };

      axios
        .post(
          global.api.baseUrlChamefacil +
            "/Usuario/InserirPrioridade?filaInternaId=" +
            this.filaAtendimento.Id,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          localStorage.setItem(
            "especialidadeAtendimento",
            JSON.stringify(this.filaAtendimento.Nome)
          );
          this.dialogEspecialidade = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: "",
            color: "sucess",
          });
          this.filaEspecialidadeTitulo = this.filaAtendimento.Nome;
        })
        .catch((error) => {
          console.log({ error });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    listarFilasDisponiveis() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(global.api.baseUrlChamefacil + "/LocalChamada/ListarDisponiveis", {
          headers,
        })
        .then(async (resp) => {
          console.log(resp.data);
          this.selectFilas = await resp.data.localChamada.map(function(local) {
            return { id: local.Id, text: local.Nome };
          });
        });
    },
    async dialogLocal() {
      await this.listarFilasDisponiveis();
      this.dialogAlocarLocal = true;
    },
    alocarLocal() {
      const headers = { token: this.tokenChamefacil };
      const alocarFila = this.selectFilas.filter(
        (item) => item.text === this.filaAlocada
      );
      this.id_filaAlocada = alocarFila[0].id;

      axios
        .post(
          global.api.baseUrlChamefacil + "/localchamada/alocarlocal",
          { IdLocal: this.id_filaAlocada },
          { headers }
        )
        .then((resp) => {
          console.log({ resp });
          localStorage.setItem(
            "idFilaAlocada",
            JSON.stringify(this.id_filaAlocada)
          );
          localStorage.setItem(
            "localAtendimento",
            JSON.stringify(this.filaAlocada)
          );
          this.localAtendimento = this.filaAlocada;
          this.dialogAlocarLocal = false;
          // this.$_ACTIONS_showSnackbarMessage({
          //   message: this.$global.messages.internalServerError,
          //   color: "sucess",
          // });
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    chamar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/chamarproximainterna",
          {},
          { headers }
        )
        .then((resp) => {
          this.$_ACTIONS_setPetEncaminhar({});
          console.log(resp.data.Senha);
          this.infoPaciente = resp.data.Senha;
          this.idChamadaInterna = resp.data.Senha.IdChamadaInterna;
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          this.infoChamadaKey += 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    tocar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/tocarinterna?chamadaInternaId=" +
            this.infoPaciente.IdChamadaInterna,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.loading = false;
        });
    },
    async encaminhar() {
      this.especialidadeSelecionada = "";
      this.observacoes = "";
      this.selectedTutor = "";
      this.selectedPet = "";
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/localChamada/ListarFilasInternas?id=" +
            this.id_filaAlocada,
          { headers }
        )
        .then(async (resp) => {
          this.selectEncaminhar = await resp.data.localChamada.map(function(
            local
          ) {
            return { id: local.Id, text: local.Nome };
          });
          this.loading = false;
          this.dialogEncaminhar = true;
          this.idRequisicaoProcedimento =
            localStorage.getItem("idRequisicaoProcedimento") || 0;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    encaminharPara() {
      this.loadingEncaminhar = true;
      let usuarioNome = JSON.parse(
        sessionStorage.vuex
      ).usuario.dados.nome.split(" ");
      usuarioNome = `${usuarioNome[0]} ${usuarioNome[1] ? usuarioNome[1] : ""}`;
      this.tutor_id = this.infoPaciente.TutorCodigo;
      this.pet_id = this.infoPaciente.PetCodigo;
      const encaminharPara = this.selectEncaminhar.filter(
        (item) => item.text === this.especialidadeSelecionada
      );
      const id_filaInterna = encaminharPara[0].id;

      const realocarFila = {};
      console.log(realocarFila);

      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil_api + "/Atendimento/RealocarInternaV2",
          {
            chamadaInternaId: this.infoPaciente.IdChamadaInterna,
            filaInternaId: id_filaInterna,
            TutorCodigo: this.tutor_id,
            PetCodigo: this.pet_id,
            RequisicaoId: 0,
            ObservacaoExtra: `${this.infoPaciente.ObservacaoExtra || ""}
             ${this.observacoes && "|" + usuarioNome + ":"} ${
              this.observacoes
            }`,
            NomePet: this.infoPaciente.NomePet,
            NomeTutor: this.infoPaciente.NomeTutor,
            Prioridade: parseInt(this.urgencia),
          },
          { headers }
        )
        .then((resp) => {
          if (resp.data.sucesso) {
            this.infoPaciente = {};
            localStorage.removeItem("infoPaciente");
            this.infoChamadaKey += 1;
            this.loadingEncaminhar = false;
            this.dialogEncaminhar = false;
            this.especialidadeSelecionada = "";
            this.IdChamadaInterna = "";
            localStorage.removeItem("idRequisicaoProcedimento");
            this.$_ACTIONS_setPetEncaminhar({});
          } else {
            this.loadingEncaminhar = false;
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.data.Mensagem,
              color: "error",
            });
          }
        })
        .catch((error) => {
          this.loadingEncaminhar = false;
          this.dialogEncaminhar = false;
          this.especialidadeSelecionada = "";
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: error.response.data.mensagem,
            color: "error",
          });
        });
    },
    aguardar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil_api +
            "/atendimento/aguardarinterna?chamadainternaId=" +
            this.infoPaciente.IdChamadaInterna,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.$_ACTIONS_setPetEncaminhar({});
          this.infoChamadaKey += 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: error.response.data.mensagem,
            color: "error",
          });
        });
    },
    finalizar() {
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/finalizarinterna",
          {
            IdSenha: this.infoPaciente.IdSenha,
            IdLocal: 0,
            IdFilaInterna: 0,
            UsuarioSenha: "",
            UsuarioAtendente: "",
          },
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.infoChamadaKey += 1;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },

    chamarSenhaLista(idChamada) {
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/chamarsenhainternaespecifica?chamadaInternaId=" +
            idChamada,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = resp.data.Senha;
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          this.infoChamadaKey += 1;
          this.loading = false;
        });
    },
    async atender() {
      this.loading = true;
      if (!this.infoPaciente.PetCodigo) {
        alert(
          "O código do pet não está vinculado a esse atendimento. Verificar no chamefacil."
        );
      }
      this.petCodigo = this.infoPaciente.PetCodigo || 0;
      const petService = new PetService();
      this.petNome = await petService
        .getPetById(this.petCodigo)
        .then((resp) => resp.nome_pet);
      this.loading = false;
      this.dialogAtender = true;
    },

    // getTutores() {
    //   const pacienteService = new PacienteService();
    //   this.tutores = pacienteService.getPacienteByName(
    //     {
    //       onSucess: (status) => (body) => {
    //         if (status === 200) {
    //           this.tutores = body.data.pacientes;
    //           console.log(this.tutores);
    //         } else {
    //           this.tutores = [];
    //         }
    //       },
    //       onError: (error) => {
    //         console.error(error);
    //         this.tutores = [];
    //       },
    //       onEnd: () => {
    //         this.loadingSearch = false;
    //       },
    //     },
    //     ""
    //   );
    // },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 15px 1px rgba(158, 158, 158, 1);
  -moz-box-shadow: 0px 0px 15px 1px rgba(158, 158, 158, 1);
  box-shadow: 0px 0px 15px 1px rgba(158, 158, 158, 1);
  margin: 20px auto;
  width: 100%;
  border-radius: 10px;
}
.display-senha {
  border: 1px solid rgb(71, 70, 70);
  border-radius: 10px;

  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h1 {
  font-size: 100px;
}
.buttons {
  flex-wrap: wrap;
  width: 110%;
}

.buttons .btn {
  height: 30px;
  width: 95px;
}
.btnEscolherfila {
  position: absolute;
  top: 2.3rem;
  right: 1.5rem;
}
.dialogButtonEncaminhar {
  height: 20px;
  width: 120px;
}
@media (max-width: 1080px) {
  .top-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .senha-container {
    flex-direction: row !important;
    gap: 2rem;
    margin-top: 1rem;
  }
  .buttons {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}
</style>
